import React, { useState, useEffect } from 'react';
import { Grid, Button } from "@mui/material";
import logo from "../../../resources/images/login/logo.png";

export default function SectionWelcome(props) {
  const { activeModal } = props;
  const [fecha, setFecha] = useState({
    dia: 0,
    hora: 0,
    minutos: 0,
    segundos: 0
  });

  useEffect(() => {
    time();
  }, [])

  const time = () => {
    // Se establece la fecha de donde se empezara a contar de manera regresiva idioma en ingles
    var countDownDate = new Date("Nov 3, 2022 08:00:00").getTime();

    // Se actualiza la cuenta regresevia cada segundo 
    var x = setInterval(function () {

      // Se optiene la fecha de hoy
      var now = new Date().getTime();

      // Se encuentra el tiempo restante de la fecha actual a la fecha final
      var distance = countDownDate - now;

      // Se calcula el tiempo para dias, horas, minutos y segundos
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Se establece el resultado en el estado para mostrarse posteriormente
      setFecha({
        dia: days,
        hora: hours,
        minutos: minutes,
        segundos: seconds
      })

      // Si el tiempo regresivo llega a 0 se detiene la cuenta regresica
      if (distance < 0) {
        clearInterval(x);
        setFecha({
          dia: 0,
          hora: 0,
          minutos: 0,
          segundos: 0
        })
      }
    }, 1000);
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={5}>
      <div className="fullHeigth contenedor-logo">
        <div className="overlap">
          <div className="full center ">
            <img src={logo} alt="logo" className="logo" />
            <div className="welcome-text full center ">
              <h1>¡Bienvenido!</h1>
              <div className="full center">
                <p>Durante 3 días podrás intercambiar, conocer y compartir información con expertos y colegas sobre las tendencias y los retos que enfrentan las organizaciones en torno al desarrollo organizacional y recursos humanos.</p>
                <p>FALTAN:</p>
                <p>{`${fecha.dia} días, ${fecha.hora} horas, ${fecha.minutos} minutos, ${fecha.segundos} segundos`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
