import React, { useEffect } from "react";
import { Container, Grid, Button, Paper } from '@material-ui/core';
import ScrollToTop from "react-scroll-to-top";
import { reporte } from '../../../resources/js/functions';
import ValidateAdmin from "../../../components/ValidateAdmin";
import Header from "../../../components/HeaderAdmin";
import "./styles.scss";

export default function Home() {
  useEffect(() => {
  }, []);
  let backgroundStyles = `#root::after{background: #fff};`;

  return (
    <>
      <ValidateAdmin>
        <Header>
          <style>{backgroundStyles}</style>
          <div id="homeAdmin">
            <ScrollToTop
              smooth
              color="#fff"
              style={{ backgroundColor: `#C1212A`, padding: "7px 0" }}
            />
            {/* <Paper elevation={3} style={{ padding: '15px' }}>
              <Container maxWidth="lg" className="border-reports">
                <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                  <Grid item lg={10} md={12} sm={12} xs={12}>
                    <h2>
                      Playbook integrador e infografía interactiva. Reporte de descargas.
                    </h2>
                  </Grid>
                  <Grid item lg={2} md={12} sm={12} xs={12}>
                    <Button
                      className="btn"
                      onClick={reporte}
                    >
                      Descargar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Paper> */}
          </div>
        </Header>
      </ValidateAdmin>
    </>
  );
}
