import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VideocamIcon from '@mui/icons-material/Videocam';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function MenuSuperAdministrador() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [openList, setOpenList] = React.useState(false);

	useEffect(() => {
		setOpenList(
			window.location.pathname === '/admin/conferencias/activar' || window.location.pathname === '/admin/conferencias/reporte-de-likes' ? true : false
		);
	}, []);

	return (
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader component='div' id='estadisticas'>
						Administración
					</ListSubheader>
				}
			>
				<ListItem button className='titulo-menu' onClick={() => setOpenList(!openList)}>
					<ListItemIcon>
						<VideocamIcon />{' '}
					</ListItemIcon>
					<ListItemText primary='Conferencias' />
					{openList ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openList} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							button
							onClick={() => navigate('/admin/conferencias/activar')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/conferencias/activar' ? 'active' : ''}`}
						>
							<ListItemText primary='Activar' />
						</ListItem>
						<ListItem
							button
							onClick={() => navigate('/admin/conferencias/reporte-de-likes')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/conferencias/reporte-de-likes' ? 'active' : ''}`}
						>
							<ListItemText primary='Likes' />
						</ListItem>
					</List>
				</Collapse>
			</List>
		</>
	);
}
