import React, { useState, useEffect } from "react";
import { db } from "../../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { Grid, Container, Switch, Button, Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Swal from "sweetalert2";
import Header from "../../../../components/HeaderAdmin";
import InputSearch from "../../../../components/InputSearch";
import ValidateAdmin from "../../../../components/ValidateAdmin";
import { getCollections, updateData, getDate } from "../../../../resources/js/functions";
import { reporteConferencia1, reporteConferencia2, reporteConferencia3, reporteConferencia4, reporteConferencia6, reporteConferencia7, reporteConferencia8, reporteConferencia9, reporteConferencia10, reporteConferencia11, reporteConferencia12, reporteConferencia13 } from "../../functionsReportes";
import { JsonToExcel } from "react-json-to-excel";
import "./styles.scss";

export default function Administration() {
    const [data, setData] = useState([]);
    const [usuarios, setUSuarios] = useState([]);
    const [encuestaGeneral, setEncuestaGeneral] = useState([]);

    useEffect(() => {
        getConferences();
        getUsuarios();
        getEncuestaGeneral();
        const dbRef = ref(db, "conferencias/");
        onChildChanged(dbRef, (data) => {
            getConferences();
        });
    }, []);

    const getConferences = async () => {
        let json = {}
        let array = [];
        let sumaLikes = 0;
        let sumaDislikes = 0;
        const conferencias = await getCollections("conferencias");
        const likes = await getCollections("likes");

        Object.values(conferencias).map(val => {
            const result = likes.filter(value => value.idConferencia === val.id);
            // console.log(result);
            // Object.values(conferencias).map(idLikes => {
            //   if (val.id === idLikes.idConferencia) {
            // if (val.like === true) {
            //   sumaLikes++;
            // } else {
            //   sumaDislikes++;

            // }
            //     array.push({
            //       ...val,
            //       likes: sumaLikes,
            //       dislikes: sumaDislikes
            //     })
            //   } else {
            //     array.push({
            //       ...val,
            //       likes: sumaLikes,
            //       dislikes: sumaDislikes
            //     })
            //   }
            // })
        })
        // setData(conferencias);
    };

    const getUsuarios = async () => {
        const array = [];
        const usuarios = await getCollections("users");
        Object.values(usuarios).map(val => {
            array.push({
                Nombre: `${val.nombre} ${val.apellido}`,
                // Apellido: val.apellido,
                Correo: val.correo,
                Empresa: val.empresa,
                Cargo: val.puesto,
                Telefono: val.telefono,
                Pais: val.pais,
                Registro: val.fechaRegistro,
                UltimaConexion: val.ultimaConexion,
            });
        });
        setUSuarios(array);

    }

    const getEncuestaGeneral = async () => {
        const arrayResult = [];
        const results = await getCollections("encuestaCertificado");
        Object.values(results).map(val => {
            arrayResult.push({
                "correo": val.correo,
                "¿Cómo fue tu experiencia en la 2da cumbre Spira: RIF?": val.preguntaDos,
                "¿Cómo calificarías la calidad del evento?": val.preguntaTres,
                "¿Llenaron tus expectativas los ponentes invitados?": val.preguntaCuatro,
                "¿Cuál o cuáles fueron los días más interesante para ti?": val.preguntaCinco,
                "¿Sientes que fue útil la información que recibiste?": val.preguntaSeis,
                "¿Cómo te enteraste del evento?": val.preguntaSiete,
                "¿Conocías Spira?": val.preguntaOcho,
                "¿Te gustaría participar en un próximo evento?": val.preguntaNueve,
                "¿Tienes algún comentario, felicitación, recomendación u oportunidad de mejora para nosotros?": val.preguntaDiez,
                "Fecha de registro": val.fechaDeRegistro,
            });
        });
        setEncuestaGeneral(arrayResult);
    }

    const activarCertificado = async () => {
        const configuracion = await getCollections("configuracion/");
        Swal.fire({
            title: `${configuracion[1].certificado ? "¿Deseas desactivar el certificado?" : "¿Deseas activar el certificado?"}`,
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#f38f04",
            cancelButtonColor: "#e10613",
            denyButtonText: `Cancelar`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                updateData("configuracion", "i5sawdj5", { certificado: !configuracion[1].certificado }).then(() => {
                    // window.location.reload()
                });
            }
        });
    };
    let backgroundStyles = `#root::after{background: #e9e9e9};`;
    return (
        <ValidateAdmin>
            <Header />
            <style>{backgroundStyles}</style>

            <div id="likes">
                <Container maxWidth="lg">
                    <Paper elevation={3} style={{ padding: '15px' }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow className="abner">
                                                <TableCell>
                                                    <div className="titulo-encuesta">Conferencia</div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="titulo-encuesta" style={{ textAlign: 'center' }}>Likes</div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="titulo-encuesta" style={{ textAlign: 'center' }}>Dislike</div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="titulo-encuesta" style={{ textAlign: 'center' }}>Reportes</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Bienvenida: Re-Evolución en la Formación</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia1()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Lecciones aprendidas y buenas prácticas en un mundo hibrido</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia2()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Lifelong learning: Aprendizaje sustentable</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia3()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Great resignation: El gran reto de fidelizar talentos</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia4()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Del salón de clases al metaverso</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia6()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Ed Tech camino a la Hiper-personalización</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia7()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Tin can API - IA de la formación y el desarrollo</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia8()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Taller: Neurociencia aplicada al desempéño de las presonas</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia9()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Reinventando el modelo de competencias tradicional</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>12</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>1</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia10()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>People Analytics, la revolución de los datos en RRHH</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>6</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>1</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia11()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>El líder RIF</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>10</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia12()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'left' }}>Taller Spira</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="nombre-conferencia" style={{ textAlign: 'center' }}>0</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div className="center">
                                                        <div className="btn-azul cursor" style={{ textAlign: 'center', width: '120px', padding: '10px', margin: 'auto' }} onClick={() => reporteConferencia13()}>Descargar</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>

            </div>
        </ValidateAdmin>
    );
}
