import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";
import { Grid, TextField, Button } from "@mui/material";
import { validateEmail, updateData, getDate, searchDataByValue } from "../../../resources/js/functions";

export default function FormLogin(props) {
  const { activeView } = props;
  const auth = getAuth();
  auth.useDeviceLanguage();
  const [campos, setCampos] = useState({});
  const [error, setError] = useState({ noUser: false, correo: false });
  const navigate = useNavigate();

  const validar = async (e) => {
    e.preventDefault();
    if (campos.correo) {
      if (!validateEmail(campos.correo)) {
        setError({ ...error, correo: "Ingresa un correo valido" });
        return null;
      }
    } else {
      setError({ ...error, correo: "El campo correo electrónico esta vacio." });
      return null;
    }

    const minusculas = campos.correo.toLowerCase();
    const result = await searchDataByValue('users', 'correo', minusculas);
    if (Object.values(result).length > 0) {

      updateData("users", result.id, { ultimaConexion: getDate() })
        .then(() => {
          localStorage.setItem("3c393f98", result.id);
          navigate("/home");
        })
        .catch((e) => console.log(e));
    } else {
      Swal.fire({
        title: "Error",
        text: "Correo no registrado",
        icon: "error",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#f38f04",
      });

    }
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={7}>
      <form onSubmit={validar}>
        <div className="contenedor-form-login">
          <div className="form-registro">
            <div className="text-form">Inicia sesión</div>
             <TextField autoComplete="true" type="email" fullWidth size="small" label="Correo electrónico" variant="filled" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
            &nbsp;
            <div className="full space-footer-form">
             <Button type="submit" className="">
                Iniciar sesión
              </Button>
               <span className="register">
                ¿Aún no tienes cuenta? &nbsp;&nbsp;&nbsp;
                <span className="register-text" onClick={() => activeView(2)}>
                  Regístrate
                </span>
              </span>
            </div>
              
          </div>
        </div>
      </form>
    </Grid>
  );
}
