import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import parse from "html-react-parser";
import { top, getCollections, stringToArray } from "../../../../resources/js/functions";
import "react-alice-carousel/lib/alice-carousel.css";

const diaUno = [1, 2, 3, 4];
const diaDos = [6, 7, 8, 9];
const diaTres = [10, 11, 12, 13];

export default function Carousel() {
  const [dataConferencias, setDataConferencias] = useState([]);
  const [day, setDay] = useState(2);

  useEffect(() => {
    getConferencias();
    top();
    const d = new Date();
    const idDay = d.getDay();
    if (idDay === 2 || idDay === 3 || idDay === 4) {
      setDay(idDay);
    }
  }, []);

  const getConferencias = async () => {
    const data = await getCollections("conferencias");
    const speakers = await getCollections("speakers");
    const array = [];

    if(day === 2){
      Object.values(diaUno).map((id) => {
        Object.values(data).map((valData) => {
          if (id === valData.id) {
            let arraySpeaker = stringToArray(valData.speakers);
            const dataSpeaker = speakers[arraySpeaker[0] - 1];
            const json = {
              ...valData,
              dataSpeaker,
            };
            array.push(json);
          }
        });
      });
    }

    if(day === 3){
      Object.values(diaDos).map((id) => {
        Object.values(data).map((valData) => {
          if (id === valData.id) {
            let arraySpeaker = stringToArray(valData.speakers);
            const dataSpeaker = speakers[arraySpeaker[0] - 1];
            const json = {
              ...valData,
              dataSpeaker,
            };
            array.push(json);
          }
        });
      });
    }
    
    if(day === 4){
      Object.values(diaTres).map((id) => {
        Object.values(data).map((valData) => {
          if (id === valData.id) {
            let arraySpeaker = stringToArray(valData.speakers);
            const dataSpeaker = speakers[arraySpeaker[0] - 1];
            const json = {
              ...valData,
              dataSpeaker,
            };
            array.push(json);
          }
        });
      });
    }

    setDataConferencias(array);
  };

  const cualCategoria = (cat) => {
    let color = "";
    switch (cat) {
      case "Foro":
        color = "border-cat-uno";
        break;
      case "Entrevista":
        color = "border-cat-dos";
        break;
      case "Ponencia":
        color = "border-cat-tres";
        break;
      case "Taller":
        color = "border-cat-cuatro";
        break;
      case "Panel":
        color = "border-cat-cinco";
        break;

      default:
        break;
    }
    return color;
  };

  const cualColor = (cat) => {
    let color = "";
    switch (cat) {
      case "Foro":
        color = "categoria-uno";
        break;
      case "Entrevista":
        color = "categoria-dos";
        break;
      case "Ponencia":
        color = "categoria-tres";
        break;
      case "Taller":
        color = "categoria-cuatro";
        break;
      case "Panel":
        color = "categoria-cinco";
        break;

      default:
        break;
    }
    return color;
  };

  return (
    <AliceCarousel
      mouseTrackingEnabled
      items={dataConferencias}
      autoPlayInterval="4000"
      autoPlay={true}
      infinite={true}
      mouseTracking
      startIndex={0}
      slideToIndex={0}
      disableButtonsControls
      // disableDotsControls
      responsive={{
        425: {
          items: 1,
        },
        640: {
          items: 1,
        },
        1024: {
          items: 3,
        },
      }}
    >
      {dataConferencias.map((i, index) => {
        return (
          <div className={`target-conference2 ${cualCategoria(i.categoria)}`} key={index}>
            {/* <div className={`full right date-conference bold ${cualColor(i.categoria)}`}>{i.fecha}</div> */}
            <div className={`categorie bold ${cualColor(i.categoria)}`} style={{ textTransform: "capitalize" }}>
              {i.categoria}
            </div>
            <div className={`name-conference bold}`}>{i.nombreConferencia}</div>
            <div className="full">
              <div className="name-conferencista full padres">
                <img src={i.dataSpeaker.urlFoto} alt="img" className="hijoss" style={{ width: "40px", marginRight: "10px" }} />
                <span className={`bold hijoss ${cualColor(i.categoria)}`} style={{ display: "inline-block" }}>
                  <span>
                    {i.dataSpeaker.nombre} <br />
                    <span className={`job-conferencista`}>{`${i.dataSpeaker.puesto.length > 35 ? `${i.dataSpeaker.puesto.substring(0, 30)}...` : i.dataSpeaker.puesto}`}</span>
                    <br />
                    <span className={`job-conferencista`}>{`${i.dataSpeaker.empresa}`}</span>
                  </span>
                </span>
              </div>
            </div>
            <p>{parse(`${i.descripcion.substring(0, 100)}...`)} </p>
            <p>Duración: {i.duracion} hora.</p>
          </div>
        );
      })}
    </AliceCarousel>
  );
}
