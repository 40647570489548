import React from "react";
import { Grid, Container } from "@material-ui/core";
// import SectionOurProducts from "../SeccionNuestrosProductos";

export default function SectionSpace() {
  return (
    <>
      <div id="section-space">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>¿Estás listo para un espacio personalizado?</h1>
              <p>
                ¡Hola! Aquí puedes agendar un espacio personalizado para que hablemos sobre todos los temas vistos en las diferentes sesiones y para que encontremos nuevas oportunidades para alcanzar tus objetivos, desarrollar a tu equipo o cómo transformar tu organización para ser cada vez más innovadores y alcanzar las metas propuestas.
              </p>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>Estos son nuestros productos:</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <SectionOurProducts />
            </Grid> */}
          </Grid>
        </Container>
      </div>
    </>
  );
}
