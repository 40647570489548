// import { ref, onValue, set, update, remove, onChildChanged, orderByChild, equalTo, get, query } from "firebase/database";
// import { db } from "../../config/firebase";
import { searchData, cambiarTextos, getDate, getCollections } from "../../resources/js/functions";
let xlsx = require("json-as-xlsx");
const administradores = [
  "aYXXdplkAER1nNwvPkmun3YYvsx1",
  "iAAqqQ2CTUSGYWofUPDWQXRse203",
  "DXynheWU3vQRRaNAv4cDWE4Cnvk2",
  "EtQdpdenQZaybAoJVCUKhHbnFT33",
  "XYeg9NxBpCQAOG7wBq9jbjxq3As1",
  "AJp4yAkJh8fmaiXxRlAp6tINfMw1",
  "AJp4yAkJh8fmaiXxRlAp6tINfMw1",
  "rMZyBHcEQJMVQ558e65soE9ktRH2",
];

// reporte de satisfaccion por conferencias
export async function reporteConferencia(id) {
  const conferencia = await searchData("conferencias", id);
  const results = await getCollections("encuestas", id);
  const values = Object.values(results[0]);

  const fecha = cambiarTextos(getDate());
  const nombre = cambiarTextos(conferencia.nombreConferencia);
  let data = [
    {
      sheet: "Reporte",
      columns: [
        { label: "¿El tema del live es relevante para ti o tu organización?", value: "preguntaUno" }, // Top level data
        { label: "En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?", value: "preguntaDos" }, // Top level data
        { label: "En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?", value: "preguntaTres" }, // Top level data
        { label: "fecha de registro", value: "fechaRegistro" }, // Top level data
      ],
      content: values,
    },
  ];

  let settings = {
    fileName: `${nombre}-${fecha}`, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(data, settings);
}

// reporte de usuarios registrados
export async function reporteUsuarios() {
  const usuarios = await getCollections("users");
  const valuesUsuarios = Object.values(usuarios);
  const array = [];
  Object.values(valuesUsuarios).map((val) => {
   if (administradores.indexOf(val.id) === -1) {
        array.push(val);
   }    
  });

  // const results = await getCollections("encuestas", id);

  const fecha = cambiarTextos(getDate());
  let data = [
    {
      sheet: "Usuarios",
      columns: [
        { label: "ID", value: "id" }, // Top level data
        { label: "Nombre", value: "nombre" }, // Top level data
        { label: "Apellido", value: "apellido" }, // Top level data
        { label: "Correo electrónico", value: "correo" }, // Top level data
        { label: "Número de teléfono", value: "numTelefono" }, // Top level data
        { label: "Empresa", value: "empresa" }, // Top level data
        { label: "Cargo", value: "cargo" }, // Top level data
        { label: "País", value: "pais" }, // Top level data
        { label: "Fecha de registro", value: "fechaRegistro" }, // Top level data
        { label: "Ultima conexión", value: "ultimaConexion" }, // Top level data
      ],
      content: array,
    },
  ];

  let settings = {
    fileName: `usuarios-${fecha}`, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(data, settings);
}

// reporte de satifaccionde plataforma
export async function reporteEncuestaGeneral() {
    const results = await getCollections("encuestaCertificado");
    const valuesUsuarios = Object.values(results);
    const fecha = cambiarTextos(getDate());
    let data = [
      {
        sheet: "Encuesta general",
        columns: [
          { label: "¿Cuál es tu correo? ", value: "correo" }, // Top level data
          { label: "¿Cómo fue tu experiencia en la 2da cumbre Spira:  RIF? ", value: "preguntaDos" }, // Top level data
          { label: "¿Cómo calificarías la calidad del evento? ", value: "preguntaTres" }, // Top level data
          { label: "¿Llenaron tus expectativas los ponentes invitados? ", value: "preguntaCuatro" }, // Top level data
          { label: "¿Cuál fue el día más interesante para ti? ", value: "preguntaCinco" }, // Top level data
          { label: "¿Sientes que fue útil la información que recibiste? ", value: "preguntaSeis" }, // Top level data
          { label: "¿Cómo te enteraste del evento?", value: "preguntaSiete" }, // Top level data
          { label: "¿Conocías Spira? ", value: "preguntaOcho" }, // Top level data
          { label: "¿Te gustaría participar en un próximo evento? ", value: "preguntaNueve" }, // Top level data
          { label: "¿Tienes algún comentario, felicitación, recomendación u oportunidad de mejora para nosotros? Compártela aquí", value: "preguntaDiez" }, // Top level data
          { label: "fechaRegistro", value: "fechaDeRegistro" }, // Top level data
        ],
        content: valuesUsuarios,
      },
    ];
  
    let settings = {
      fileName: `encuesta-general-${fecha}`, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
  
    xlsx(data, settings);
}

// Conferencias
export async function reporteConferencia1() {
	const results = await getCollections('encuestas/1');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-1-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia2() {
	const results = await getCollections('encuestas/2');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-2-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia3() {
	const results = await getCollections('encuestas/3');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-3-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia4() {
	const results = await getCollections('encuestas/4');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-4-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia6() {
	const results = await getCollections('encuestas/6');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-6-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia7() {
	const results = await getCollections('encuestas/7');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-7-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia8() {
	const results = await getCollections('encuestas/8');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-8-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia9() {
	const results = await getCollections('encuestas/9');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-9-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia10() {
	const results = await getCollections('encuestas/10');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-10-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia11() {
	const results = await getCollections('encuestas/11');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-11-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia12() {
	const results = await getCollections('encuestas/12');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-12-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}
export async function reporteConferencia13() {
	const results = await getCollections('encuestas/13');
	// const usuarios = await getCollections('usuarios');
  console.log(results);
	const fecha = getDate();
	const data = [];
	let json = {};
	Object.values(results).map((val) => {
    json = {
      preguntaUno: val.preguntaUno,
      preguntaDos: val.preguntaDos,
      preguntaTres: val.preguntaTres,
      preguntaCuatro: val.preguntaCuatro,
      // fechaDeRegistro: val.fechaRegistro,
    };
		data.push(json);
	});

	let dataXls = [
		{
			sheet: 'Reporte',
			columns: [
				{ label: '¿El tema del live es relevante para ti o tu organización?', value: 'preguntaUno' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?', value: 'preguntaDos' },
				{ label: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live?', value: 'preguntaTres' },
				{ label: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo?', value: 'preguntaCuatro' },
				// { label: 'Fecha de registro', value: 'fechaDeRegistro' },
			],
			content: data,
		},
	];

	let settings = {
		fileName: `conferencia-13-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(dataXls, settings);
}