import React from "react";
import { Grid } from "@material-ui/core";
import parse from "html-react-parser";

export default function SpeakersConfertencias(props) {
  const speakersActive = props.speakersActive;
  // console.log(speakersActive);
  return (
    <>
      <Grid item lg={11} md={11} xs={12}>
        <div className="titulos">{speakersActive.length > 1 ? "Acerca de los especialistas" : "Acerca del especialista"}</div>
      </Grid>
      {Object.keys(speakersActive).map((val, index) => {
        const info = speakersActive[val]
        // console.log(info.urlFoto);
        return (
          <Grid item lg={11} md={11} xs={12} key={index}>
            <div className="full">
              <div className="name-conferencista full ">
                {/* <div style={{backgroundImage: `url(${info.urlFoto})`}}> */}
                <div >
                  <img className="img-conferencista" style={{borderRadius: '50%'}} src={info.urlFotoCuadrada} alt="img" />
                </div>
                <span className="tendencias bold" style={{ display: "inline-block" }}>
                  <div className="job-conferencista">
                    <div className="name">{`${info.nombre}`}</div>
                    {/* <span > */}
                      <div className="job">
                        
                      {info.empresa !== ' ' ? `${info.puesto} - ${info.empresa}` : `${info.puesto}`}
                        </div>
                    {/* </span> */}
                    <div className="description-conferencista">{parse(info.descripcion)}</div>
                  </div>
                </span>
              </div>
            </div>
          </Grid>
        );
      })}
    </>
  );
}
