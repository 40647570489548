import React, { useEffect } from "react";
import { top } from '../../resources/js/functions';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ValidateUser from "../../components/ValidateUser";
import Bienvenida from "./Bienvenida";
import SeccionConferencia from "./SeccionConferencia";
import SeccionQueVeremosHoy from "./SeccionQueVeremosHoy";
import SeccionConferenciasDelDia from "./SeccionConferenciasDelDia";
import SeccionSocios from "./SeccionSocios";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

export default function Home() {
  useEffect(() => {
    top();
  }, [])

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="home">
          <Bienvenida />
          <SeccionConferencia />
          <SeccionQueVeremosHoy />
          <SeccionConferenciasDelDia />
          <SeccionSocios /> 
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
