export default function ValidateUser(props) {
  const { children } = props;
  const data = localStorage.getItem("531ac50224f238df5d6efdaf36507cf2");
  let content = "";

  if (data) {
    content = <div>{children}</div>;
  } else {
    window.location.replace("/admin/login");
  }
  return content;
}
