import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import parse from "html-react-parser";
import { isDesktop, isMobile } from 'react-device-detect';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { downloadFile, top } from "../../../resources/js/functions";

const d = new Date();

// card1
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand, id }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SectionSchedule() {
  const [day, setDay] = useState(2);
  const navigate = useNavigate();

  // 8 am
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [expanded8, setExpanded8] = useState(false);
  const [expanded9, setExpanded9] = useState(false);
  const [expanded10, setExpanded10] = useState(false);
  const [expanded11, setExpanded11] = useState(false);
  const [expanded12, setExpanded12] = useState(false);
  const [expanded13, setExpanded13] = useState(false);

  useEffect(() => {
    if (d.getDay() === 2 || d.getDay() === 3 || d.getDay() === 4) {
      setDay(d.getDay())
    } else {
      setDay(2)
    }
    top();
  }, [])

  const cualCategoria = (cat) => {
    let color = "";
    switch (cat) {
      case "Foro":
        color = "border-cat-uno";
        break;
      case "Entrevista":
        color = "border-cat-dos";
        break;
      case "Ponencia":
        color = "border-cat-tres";
        break;
      case "Taller":
        color = "border-cat-cuatro";
        break;
      case "Panel":
        color = "border-cat-cinco";
        break;

      default:
        break;
    }
    return color;
  };

  const cualColor = (cat) => {
    let color = "";
    switch (cat) {
      case "Foro":
        color = "categoria-uno";
        break;
      case "Entrevista":
        color = "categoria-dos";
        break;
      case "Ponencia":
        color = "categoria-tres";
        break;
      case "Taller":
        color = "categoria-cuatro";
        break;
      case "Panel":
        color = "categoria-cinco";
        break;

      default:
        break;
    }
    return color;
  };

  const handleExpandClick = (id) => {
    switch (id) {
      case 0:
        setExpanded1(!expanded1);
        break;
      case 1:
        setExpanded2(!expanded2);
        break;
      case 2:
        setExpanded3(!expanded3);
        break;
      case 3:
        setExpanded4(!expanded4);
        break;
      case 4:
        setExpanded5(!expanded5);
        break;
      case 5:
        setExpanded6(!expanded6);
        break;
      case 6:
        setExpanded7(!expanded7);
        break;
      case 7:
        setExpanded8(!expanded8);
        break;
      case 8:
        setExpanded9(!expanded9);
        break;
      case 9:
        setExpanded10(!expanded10); //
        break;
      case 10:
        setExpanded11(!expanded11);
        break;
      case 11:
        setExpanded12(!expanded12);
        break;
      case 12:
        setExpanded13(!expanded13);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div id="section-schedule">
        {isMobile ? <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className="full center mb30">
                <div onClick={() => setDay(2)} className={`btn-day ${day === 2 && 'btn-azul blanco'}`}>3</div>
                <div className="full center" style={{ fontWeight: `${day === 2 && "bold"}`, fontSize: `${day === 2 && "18px"}` }}>Martess</div>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className="full center mb30">
                <div onClick={() => setDay(3)} className={`btn-day ${day === 3 && 'btn-azul blanco'}`}>4</div>
                <div className="full center" style={{ fontWeight: `${day === 3 && "bold"}`, fontSize: `${day === 3 && "18px"}` }}>Miércoles </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className="full center mb30">
                <div onClick={() => setDay(4)} className={`btn-day ${day === 4 && 'btn-azul blanco'}`}>5</div>
                <div className="full center" style={{ fontWeight: `${day === 4 && "bold"}`, fontSize: `${day === 4 && "18px"}` }}>Jueves </div>
              </div>
            </Grid>
          </Grid>
        </Container> : ''}

        {/* HORARIO DE LAS 8 AM */}
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              <div className="center hijos bold">8:00</div>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {!isMobile ? <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2}>
                <Grid item xs={4} sm={4} md={4} lg={4} className="padre center mb30">
                  <div className={`btn-day ${day === 2 && 'btn-azul blanco'}`}>3</div>
                  <div style={{ fontWeight: `${day === 2 && "bold"}`, fontSize: `${day === 2 && "18px"}` }}>Martes</div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className="padre center mb30">
                  <div className={`btn-day ${day === 3 && 'btn-azul blanco'}`}>4</div>
                  <div style={{ fontWeight: `${day === 3 && "bold"}`, fontSize: `${day === 3 && "18px"}` }}>Miércoles </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className="padre center mb30">
                  <div className={`btn-day ${day === 4 && 'btn-azul blanco'}`}>5</div>
                  <div style={{ fontWeight: `${day === 4 && "bold"}`, fontSize: `${day === 4 && "18px"}` }}>Jueves </div>
                </Grid>
              </Grid> : ''}
              {/* ESCRITORIO */}
              {!isMobile && <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Foro")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Foro")} flex`} style={{ position: "relative" }}>
                                <span>Foro</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded1} onClick={() => handleExpandClick(0)} aria-expanded={expanded1} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/1')}>Bienvenida: Re-Evolución en la Formación</div>}
                        />
                        {!expanded1 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F5%20-%20Fer%20Niizawa%2FFer_Niizawa_85x85.png?alt=media&token=c99824fa-4318-4d3e-9802-86bf91bba563" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Fer Niizawa
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Global Chief of Public Affairs & Growth Officer - PDA</div>}
                          />
                        )}
                        <Collapse in={expanded1} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F5%20-%20Fer%20Niizawa%2FFer_Niizawa_85x85.png?alt=media&token=c99824fa-4318-4d3e-9802-86bf91bba563" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Fer Niizawa
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Global Chief of Public Affairs & Growth Officer - PDA</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>El mundo est&aacute; cambiando de forma acelerada, a lo largo de la historia hemos presenciado diferentes revoluciones y es que la palabra &ldquo;revoluci&oacute;n&rdquo; tiene su origen en el verbo lat&iacute;n revolver&eacute;, &lsquo;volver a girar&rsquo; porque a diario tenemos que volver a evolucionar y adaptarnos a los cambios que surgen, conocer nuevos entornos digitales e innovar para no ser parte del pasado con metodolog&iacute;as de formaci&oacute;n efectivas y pensadas en la nueva era.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FBienvenida%20Re-Evoluci%C3%B3n%20en%20la%20Formaci%C3%B3n.ics?alt=media&token=6d141f3f-c8c4-4726-b78b-782b2ed35d20', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>

                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Ponencia")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Ponencia")} flex`} style={{ position: "relative" }}>
                                <span>Ponencia</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded10} onClick={() => handleExpandClick(9)} aria-expanded={expanded10} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/6')}>Del salón de clases al metaverso</div>}
                        />
                        {!expanded10 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F11%20-%20Juan%20Felipe%20Santos%2FJuan_F_Santos_85x85.png?alt=media&token=64500e2f-78d5-46d3-b3a6-045ee9014ce6" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Juan Felipe Santos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Viewy</div>}
                          />
                        )}
                        <Collapse in={expanded10} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F11%20-%20Juan%20Felipe%20Santos%2FJuan_F_Santos_85x85.png?alt=media&token=64500e2f-78d5-46d3-b3a6-045ee9014ce6" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Juan Felipe Santos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Viewy</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F12%20-%20Baltazar%20Rodri%CC%81guez%2FBaltazar_Rodriguez_85x85.png?alt=media&token=b5d5da00-23b7-438a-8a51-15ddb347305f" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Baltazar Rodriguez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Blockchain Teacher Fintech Diploma - Client Technical Leader</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F13%20-%20Hector%20Genis%2FHector_Genis_85x85.png?alt=media&token=42aa976e-ed4e-49db-a951-1ad685280ec4" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Hector Genis
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Socrates</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira </div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Como el mundo evoluciona, los procesos de formaci&oacute;n tambi&eacute;n lo hacen y es que el metaverso ofrece espacios virtuales con la posibilidad de interactuar y compartir conocimiento de una forma &aacute;gil y pr&aacute;ctica.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FDel%20sal%C3%B3n%20de%20clases%20al%20metaverso.ics?alt=media&token=5f7f775e-c9f2-4cbd-acbb-37e6496afe9e', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded11} onClick={() => handleExpandClick(10)} aria-expanded={expanded11} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/10')}>Reinventando el modelo de competencias tradicional</div>}
                        />
                        {!expanded11 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F17%20-%20Max%20Gonza%CC%81lez%2FMax-Gonzalez-85x85.png?alt=media&token=80014edb-dfa2-4f97-a21b-5190b211bc2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Máx González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor corporativo</div>}
                          />
                        )}
                        <Collapse in={expanded11} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F17%20-%20Max%20Gonza%CC%81lez%2FMax-Gonzalez-85x85.png?alt=media&token=80014edb-dfa2-4f97-a21b-5190b211bc2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Máx González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor corporativo</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F18%20-%20Claudia%20Sarmiento%2FClaudia-Sarmiento_85x85.png?alt=media&token=a35cbf72-6bc1-4713-80ba-27a4a44875a6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Claudia Sarmiento Gómez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Manager - Michael Page</div>}
                          />
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />}
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Desde la mirada de 4 expertos, con diferentes posiciones en el mundo del desempe&ntilde;o organizacional y la generaci&oacute;n de h&aacute;bitos productivos se resaltan los cambios que han realizado en sus organizaciones al modelo de competencias tradicional.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FReinventando%20el%20modelo%20de%20competencias%20tradicional.ics?alt=media&token=96d0ffb7-39fe-4149-9fb1-96280c819cce', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>}
              {/* MOBILE */}
              {isMobile && <Grid container direction="row" spacing={2}>
                {day === 2 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Foro")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Foro")} flex`} style={{ position: "relative" }}>
                                <span>Foro</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded1} onClick={() => handleExpandClick(0)} aria-expanded={expanded1} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/1')}>Bienvenida: Re-Evolución en la Formación</div>}
                        />
                        {!expanded1 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F5%20-%20Fer%20Niizawa%2FFer_Niizawa_85x85.png?alt=media&token=c99824fa-4318-4d3e-9802-86bf91bba563" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Fer Niizawa
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Global Chief of Public Affairs & Growth Officer - PDA</div>}
                          />
                        )}
                        <Collapse in={expanded1} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F5%20-%20Fer%20Niizawa%2FFer_Niizawa_85x85.png?alt=media&token=c99824fa-4318-4d3e-9802-86bf91bba563" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Fer Niizawa
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Global Chief of Public Affairs & Growth Officer - PDA</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira </div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>El mundo est&aacute; cambiando de forma acelerada, a lo largo de la historia hemos presenciado diferentes revoluciones y es que la palabra &ldquo;revoluci&oacute;n&rdquo; tiene su origen en el verbo lat&iacute;n revolver&eacute;, &lsquo;volver a girar&rsquo; porque a diario tenemos que volver a evolucionar y adaptarnos a los cambios que surgen, conocer nuevos entornos digitales e innovar para no ser parte del pasado con metodolog&iacute;as de formaci&oacute;n efectivas y pensadas en la nueva era.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FBienvenida%20Re-Evoluci%C3%B3n%20en%20la%20Formaci%C3%B3n.ics?alt=media&token=6d141f3f-c8c4-4726-b78b-782b2ed35d20', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>

                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
                {day === 3 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Ponencia")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Ponencia")} flex`} style={{ position: "relative" }}>
                                <span>Ponencia</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded10} onClick={() => handleExpandClick(9)} aria-expanded={expanded10} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/6')}>Del salón de clases al metaverso</div>}
                        />
                        {!expanded10 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F11%20-%20Juan%20Felipe%20Santos%2FJuan_F_Santos_85x85.png?alt=media&token=64500e2f-78d5-46d3-b3a6-045ee9014ce6" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Juan Felipe Santos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Viewy</div>}
                          />
                        )}
                        <Collapse in={expanded10} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F11%20-%20Juan%20Felipe%20Santos%2FJuan_F_Santos_85x85.png?alt=media&token=64500e2f-78d5-46d3-b3a6-045ee9014ce6" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Juan Felipe Santos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Viewy</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F12%20-%20Baltazar%20Rodri%CC%81guez%2FBaltazar_Rodriguez_85x85.png?alt=media&token=b5d5da00-23b7-438a-8a51-15ddb347305f" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Baltazar Rodriguez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Blockchain Teacher Fintech Diploma - Client Technical Leader</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F13%20-%20Hector%20Genis%2FHector_Genis_85x85.png?alt=media&token=42aa976e-ed4e-49db-a951-1ad685280ec4" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Hector Genis
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder - Socrates</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira </div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Como el mundo evoluciona, los procesos de formaci&oacute;n tambi&eacute;n lo hacen y es que el metaverso ofrece espacios virtuales con la posibilidad de interactuar y compartir conocimiento de una forma &aacute;gil y pr&aacute;ctica.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FDel%20sal%C3%B3n%20de%20clases%20al%20metaverso.ics?alt=media&token=5f7f775e-c9f2-4cbd-acbb-37e6496afe9e', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
                {day === 4 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded11} onClick={() => handleExpandClick(10)} aria-expanded={expanded11} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/4')}>Reinventando el modelo de competencias tradicional</div>}
                        />
                        {!expanded11 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F17%20-%20Max%20Gonza%CC%81lez%2FMax-Gonzalez-85x85.png?alt=media&token=80014edb-dfa2-4f97-a21b-5190b211bc2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Máx González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor corporativo</div>}
                          />
                        )}
                        <Collapse in={expanded11} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F17%20-%20Max%20Gonza%CC%81lez%2FMax-Gonzalez-85x85.png?alt=media&token=80014edb-dfa2-4f97-a21b-5190b211bc2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Máx González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor corporativo</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F18%20-%20Claudia%20Sarmiento%2FClaudia-Sarmiento_85x85.png?alt=media&token=a35cbf72-6bc1-4713-80ba-27a4a44875a6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Claudia Sarmiento Gómez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Manager - Michael Page</div>}
                          />
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />}
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Desde la mirada de 4 expertos, con diferentes posiciones en el mundo del desempe&ntilde;o organizacional y la generaci&oacute;n de h&aacute;bitos productivos se resaltan los cambios que han realizado en sus organizaciones al modelo de competencias tradicional.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FReinventando%20el%20modelo%20de%20competencias%20tradicional.ics?alt=media&token=96d0ffb7-39fe-4149-9fb1-96280c819cce', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
              </Grid>}
            </Grid>
          </Grid>
        </Container>

        {/* HORARIO DE LAS 9 AM */}
        {!isMobile && <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              <div className="center hijos bold">9:30</div>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded4} onClick={() => handleExpandClick(3)} aria-expanded={expanded4} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/3')}>Lifelong learning: Aprendizaje sustentable</div>}
                        />
                        {!expanded4 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F9%20-%20Rube%CC%81n%20Martin%2FRuben-Martin-85x85.png?alt=media&token=ae094be6-c43c-4e0b-95d2-45599f2b199d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Rubén Martín
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor Senior - Marca Personal</div>}
                          />
                        )}
                        <Collapse in={expanded4} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F9%20-%20Rube%CC%81n%20Martin%2FRuben-Martin-85x85.png?alt=media&token=ae094be6-c43c-4e0b-95d2-45599f2b199d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Rubén Martín
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultor Senior - Marca Personal</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Paula Kley
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Los procesos y la tecnolog&iacute;a evolucionan constantemente, si no continuamos creciendo y desarroll&aacute;ndonos; pronto nos quedaremos atr&aacute;s, debemos ser aprendices de por vida. Necesitamos mantener continuamente perfeccionando nuestras habilidades y actualiz&aacute;ndolas para tener una ventaja organizacional.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FLifelong%20learning%20Aprendizaje%20sustentable.ics?alt=media&token=d31719dc-6279-4a17-8222-339779899408', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className="padre"></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Ponencia")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Ponencia")} flex`} style={{ position: "relative" }}>
                                <span>Ponencia</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded2} onClick={() => handleExpandClick(1)} aria-expanded={expanded2} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/11')}>People Analytics, la revolución de los datos en RRHH</div>}
                        />
                        {!expanded2 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F19%20-%20Federico%20barcos%2FFederico_Barcos_85x85.png?alt=media&token=d3173eb1-dbf1-48ad-ac81-c243644adea7" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Federico Barcos Von der Heide
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Founder & CEO - PeopleOPTI</div>}
                          />
                        )}
                        <Collapse in={expanded2} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F19%20-%20Federico%20barcos%2FFederico_Barcos_85x85.png?alt=media&token=d3173eb1-dbf1-48ad-ac81-c243644adea7" alt="img" />}
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Federico Barcos Von der Heide
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Founder & CEO - PeopleOPTI</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<div class=""ewa-rteLine"">C&oacute;mo extraer el valor oculto de los datos de personas para lograr una gesti&oacute;n m&aacute;s efectiva del recurso m&aacute;s importante de una organizaci&oacute;n: Las Personas</div>
<div class=""ewa-rteLine"">&bull; Qu&eacute; es y para qu&eacute; sirve la ciencia de datos en RR.HH.</div>
<div class=""ewa-rteLine"">&bull; Por d&oacute;nde empezar el camino de People Analytics</div>
<div class=""ewa-rteLine"">&bull; C&oacute;mo es que otras empresas ya lo han hecho</div>
<div class=""ewa-rteLine"">&bull; &iquest;People Analytics es un mito, tendencia o realidad para RR.HH.?</div>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FPeople%20Analytics%20la%20revoluci%C3%B3n%20de%20los%20datos%20en%20RRHH.ics?alt=media&token=80463824-65c5-4f6b-8926-a1d87b61562d', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>}
        {isMobile && day === 2 && <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              <div className="center hijos bold">9:30</div>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {/* <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre"> */}
              {/* <div className="scroll-row flex " > */}
              <div className={`container-day`}>

                <div className={`${cualCategoria("Entrevista")}`}>
                  <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                    <CardHeader
                      title={
                        <>
                          <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                            <span>Entrevista</span>
                            <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                              <ExpandMore expand={expanded4} onClick={() => handleExpandClick(3)} aria-expanded={expanded4} aria-label="show more">
                                <ExpandMoreIcon />
                              </ExpandMore>
                            </span>
                          </div>
                        </>
                      }
                      subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/3')}>Lifelong learning: Aprendizaje sustentable</div>}
                    />
                    {!expanded4 && (
                      <CardHeader
                        avatar={
                          <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F9%20-%20Rube%CC%81n%20Martin%2FRuben-Martin-85x85.png?alt=media&token=ae094be6-c43c-4e0b-95d2-45599f2b199d" alt="img" />
                        }
                        title={
                          <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                            Rubén Martín
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Consultor Senior - Marca Personal</div>}
                      />
                    )}
                    <Collapse in={expanded4} timeout="auto" unmountOnExit>
                      <CardHeader
                        avatar={
                          <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F9%20-%20Rube%CC%81n%20Martin%2FRuben-Martin-85x85.png?alt=media&token=ae094be6-c43c-4e0b-95d2-45599f2b199d" alt="img" />
                        }
                        title={
                          <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                            Rubén Martín
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Consultor Senior - Marca Personal</div>}
                      />
                      <CardHeader
                        avatar={
                          <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                        }
                        title={
                          <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                            Paula Kley
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {parse(
                            `<p>Los procesos y la tecnolog&iacute;a evolucionan constantemente, si no continuamos creciendo y desarroll&aacute;ndonos; pronto nos quedaremos atr&aacute;s, debemos ser aprendices de por vida. Necesitamos mantener continuamente perfeccionando nuestras habilidades y actualiz&aacute;ndolas para tener una ventaja organizacional.</p>`
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Duración: 1 hrs.
                          <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FLifelong%20learning%20Aprendizaje%20sustentable.ics?alt=media&token=d31719dc-6279-4a17-8222-339779899408', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </div>
              </div>
              {/* </div> */}
              {/* </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Container>}
        {isMobile && day === 4 && <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              <div className="center hijos bold">9:30</div>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {/* <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre"> */}
              <div className={`container-day`}>
                <div className={`${cualCategoria("Ponencia")}`}>
                  <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                    <CardHeader
                      title={
                        <>
                          <div className={`categoria ${cualColor("Ponencia")} flex`} style={{ position: "relative" }}>
                            <span>Ponencia</span>
                            <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                              <ExpandMore expand={expanded2} onClick={() => handleExpandClick(1)} aria-expanded={expanded2} aria-label="show more">
                                <ExpandMoreIcon />
                              </ExpandMore>
                            </span>
                          </div>
                        </>
                      }
                      subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/11')}>People Analytics, la revolución de los datos en RRHH</div>}
                    />
                    {!expanded2 && (
                      <CardHeader
                        avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F19%20-%20Federico%20barcos%2FFederico_Barcos_85x85.png?alt=media&token=d3173eb1-dbf1-48ad-ac81-c243644adea7" alt="img" />}
                        title={
                          <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                            Federico Barcos Von der Heide
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Founder & CEO - PeopleOPTI</div>}
                      />
                    )}
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                      <CardHeader
                        avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F19%20-%20Federico%20barcos%2FFederico_Barcos_85x85.png?alt=media&token=d3173eb1-dbf1-48ad-ac81-c243644adea7" alt="img" />}
                        title={
                          <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                            Federico Barcos Von der Heide
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Founder & CEO - PeopleOPTI</div>}
                      />
                      <CardHeader
                        avatar={
                          <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                        }
                        title={
                          <div className={`${cualColor("Ponencia")}`} style={{ fontWeight: "bold" }}>
                            Andrés González
                          </div>
                        }
                        subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                      />

                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {parse(
                            `<div class=""ewa-rteLine"">C&oacute;mo extraer el valor oculto de los datos de personas para lograr una gesti&oacute;n m&aacute;s efectiva del recurso m&aacute;s importante de una organizaci&oacute;n: Las Personas</div>
<div class=""ewa-rteLine"">&bull; Qu&eacute; es y para qu&eacute; sirve la ciencia de datos en RR.HH.</div>
<div class=""ewa-rteLine"">&bull; Por d&oacute;nde empezar el camino de People Analytics</div>
<div class=""ewa-rteLine"">&bull; C&oacute;mo es que otras empresas ya lo han hecho</div>
<div class=""ewa-rteLine"">&bull; &iquest;People Analytics es un mito, tendencia o realidad para RR.HH.?</div>`
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Duración: 1 hrs.
                          <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FPeople%20Analytics%20la%20revoluci%C3%B3n%20de%20los%20datos%20en%20RRHH.ics?alt=media&token=80463824-65c5-4f6b-8926-a1d87b61562d', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </div>
              </div>
              {/* </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Container>}

        {/* HORARIO DE LAS 10 AM */}
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              {!isMobile && <div className="center hijos bold">10:00</div>}
              {isMobile && (day === 3) && <div className="center hijos bold">10:00</div>}
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {!isMobile && <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Foro")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Foro")} flex`} style={{ position: "relative" }}>
                                <span>Foro</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded7} onClick={() => handleExpandClick(6)} aria-expanded={expanded7} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/7')}>Foro Ed Tech camino a la Hiper-personalización</div>}
                        />
                        {!expanded7 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F14%20-%20Juanita%20Ordon%CC%83ez%2FJuanita_Ordon%CC%83ez_85x85.png?alt=media&token=5f89087e-9855-4051-aa8f-a5b2412163e3" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Juanita Ordoñez Fernández
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder and Growth - ProTalento</div>}
                          />
                        )}
                        <Collapse in={expanded7} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F14%20-%20Juanita%20Ordon%CC%83ez%2FJuanita_Ordon%CC%83ez_85x85.png?alt=media&token=5f89087e-9855-4051-aa8f-a5b2412163e3" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Juanita Ordoñez Fernández
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder and Growth - ProTalento</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Constantemente estamos en contacto con la tecnolog&iacute;a. Miramos nuestro smartphone para ver la hora, revisar nuestra agenda del d&iacute;a, e incluso posteamos algo en nuestras redes sociales. El sector educativo no es la excepci&oacute;n, y las aulas de clase tradicionales, cada d&iacute;a se est&aacute;n convirtiendo en espacios donde la tecnolog&iacute;a es imprescindible para asegurar una buena experiencia al estudiante. Por medio de la aplicaci&oacute;n pr&aacute;ctica, en la que se emplean aplicaciones y otras plataformas tecnol&oacute;gicas con la intenci&oacute;n de mejorar el aprendizaje.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FEd%20Tech%20camino%20a%20la%20Hiper-personalizaci%C3%B3n.ics?alt=media&token=0ce44ce3-5e6b-490c-8922-67c85cd68c70', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>}
              {isMobile && <Grid container direction="row" spacing={2}>
                {day === 3 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>
                    <div className={`${cualCategoria("Foro")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Foro")} flex`} style={{ position: "relative" }}>
                                <span>Foro</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded7} onClick={() => handleExpandClick(6)} aria-expanded={expanded7} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/7')}>Foro Ed Tech camino a la Hiper-personalización</div>}
                        />
                        {!expanded7 && (
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F14%20-%20Juanita%20Ordon%CC%83ez%2FJuanita_Ordon%CC%83ez_85x85.png?alt=media&token=5f89087e-9855-4051-aa8f-a5b2412163e3" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Juanita Ordoñez Fernández
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder and Growth - ProTalento</div>}
                          />
                        )}
                        <Collapse in={expanded7} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={<img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F14%20-%20Juanita%20Ordon%CC%83ez%2FJuanita_Ordon%CC%83ez_85x85.png?alt=media&token=5f89087e-9855-4051-aa8f-a5b2412163e3" alt="img" />}
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Juanita Ordoñez Fernández
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Co-Founder and Growth - ProTalento</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Foro")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Constantemente estamos en contacto con la tecnolog&iacute;a. Miramos nuestro smartphone para ver la hora, revisar nuestra agenda del d&iacute;a, e incluso posteamos algo en nuestras redes sociales. El sector educativo no es la excepci&oacute;n, y las aulas de clase tradicionales, cada d&iacute;a se est&aacute;n convirtiendo en espacios donde la tecnolog&iacute;a es imprescindible para asegurar una buena experiencia al estudiante. Por medio de la aplicaci&oacute;n pr&aacute;ctica, en la que se emplean aplicaciones y otras plataformas tecnol&oacute;gicas con la intenci&oacute;n de mejorar el aprendizaje.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FEd%20Tech%20camino%20a%20la%20Hiper-personalizaci%C3%B3n.ics?alt=media&token=0ce44ce3-5e6b-490c-8922-67c85cd68c70', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
              </Grid>}

            </Grid>
          </Grid>
        </Container>

        {/* HORARIO DE LAS 11 AM */}
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              {!isMobile && <div className="center hijos bold">11:00</div>}
              {isMobile && (day === 2) && <div className="center hijos bold">11:00</div>}
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {!isMobile && <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day `}>
                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded3} onClick={() => handleExpandClick(2)} aria-expanded={expanded3} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/2')}>Lecciones aprendidas y buenas prácticas en un mundo hibrido</div>}
                        />
                        {!expanded3 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F7%20-%20Adela%20Giral%2FAdela_Giral_85x85.png?alt=media&token=a62f5368-6d69-42cb-92e8-0d59922a0534" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Adela Giral
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora Ejecutiva de Personas - Gentera</div>}
                          />
                        )}
                        <Collapse in={expanded3} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F7%20-%20Adela%20Giral%2FAdela_Giral_85x85.png?alt=media&token=a62f5368-6d69-42cb-92e8-0d59922a0534" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Adela Giral
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora Ejecutiva de Personas - Gentera</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Para sumergirnos en este nuevo mundo h&iacute;brido es necesario contar con dos factores: tecnolog&iacute;a y un cambio en la gesti&oacute;n de los empleados, cabe se&ntilde;alar que no s&oacute;lo se trata de un esquema de trabajo h&iacute;brido. El mundo h&iacute;brido va m&aacute;s all&aacute;, es toda una cultura organizacional que mezcla las mejores pr&aacute;cticas de lo presencial con lo digital, una pr&aacute;ctica que se ha demostrado que funciona bien y produce buenos resultados, y, por lo tanto, se recomienda como modelo.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">

                </Grid>
              </Grid>}
              {isMobile && <Grid container direction="row" spacing={2}>
                {day === 2 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day `}>
                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded3} onClick={() => handleExpandClick(2)} aria-expanded={expanded3} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/2')}>Lecciones aprendidas y buenas prácticas en un mundo hibrido</div>}
                        />
                        {!expanded3 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F7%20-%20Adela%20Giral%2FAdela_Giral_85x85.png?alt=media&token=a62f5368-6d69-42cb-92e8-0d59922a0534" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Adela Giral
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora Ejecutiva de Personas - Gentera</div>}
                          />
                        )}
                        <Collapse in={expanded3} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F7%20-%20Adela%20Giral%2FAdela_Giral_85x85.png?alt=media&token=a62f5368-6d69-42cb-92e8-0d59922a0534" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Adela Giral
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora Ejecutiva de Personas - Gentera</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />

                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Para sumergirnos en este nuevo mundo h&iacute;brido es necesario contar con dos factores: tecnolog&iacute;a y un cambio en la gesti&oacute;n de los empleados, cabe se&ntilde;alar que no s&oacute;lo se trata de un esquema de trabajo h&iacute;brido. El mundo h&iacute;brido va m&aacute;s all&aacute;, es toda una cultura organizacional que mezcla las mejores pr&aacute;cticas de lo presencial con lo digital, una pr&aacute;ctica que se ha demostrado que funciona bien y produce buenos resultados, y, por lo tanto, se recomienda como modelo.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}

              </Grid>}

            </Grid>
          </Grid>
        </Container>

        {/* HORARIO DE LAS 14 AM */}
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              {!isMobile && <div className="center hijos bold">14:00</div>}
              {isMobile && (day === 3 || day === 4) && < div className="center hijos bold">14:00</div>}
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {!isMobile && <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Panel")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Panel")} flex`} style={{ position: "relative" }}>
                                <span>Panel</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded8} onClick={() => handleExpandClick(7)} aria-expanded={expanded8} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/8')}>Panel Tin can API - IA de la formación y el desarrollo</div>}
                        />
                        {!expanded8 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F15%20-%20Gustavo%20Escoqui%2FGustavo_Escoqui_85x85.png?alt=media&token=eff93046-ea4e-4572-b0b3-6e1062606a1d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Gustavo Escoqui
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Diretor de Tecnologia - 4U Edtech</div>}
                          />
                        )}
                        <Collapse in={expanded8} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F15%20-%20Gustavo%20Escoqui%2FGustavo_Escoqui_85x85.png?alt=media&token=eff93046-ea4e-4572-b0b3-6e1062606a1d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Gustavo Escoqui
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Diretor de Tecnologia - 4U Edtech</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira </div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Tin Can API, Experience Api o X-Api es conocido generalmente como &ldquo;la nueva generaci&oacute;n del est&aacute;ndar SCORM&rdquo;; si bien a nivel t&eacute;cnico podr&iacute;a entenderse as&iacute;, TinCan Api es en verdad mucho m&aacute;s que eso. Es una herramienta que nos viene a ayudar para generar una nueva forma de experiencias de &ldquo;aprendizaje ubicuo&rdquo;.</p>
<p>Lo que queremos decir es que TinCan viene a facilitar cuatro deficiencias del Scorm: A pesar de todas sus ventajas, SCORM se ha quedado atr&aacute;s en los &uacute;ltimos a&ntilde;os por los cambios que han surgido en el contexto de la capacitaci&oacute;n online:</p>
<p>El aprendizaje debe ser m&oacute;vil. No solo se produce frente a un equipo y dentro del contexto de un LMS, sino tambi&eacute;n en un viaje mientras no exista conexi&oacute;n a Internet. Hay un potencial enorme en el aprendizaje informal. El aprendizaje no puede estar limitado a un pensum formal, tambi&eacute;n debe ocurrir en contextos informales como p&aacute;ginas web, canales de streaming, redes sociales o blogs.</p>
<p>Hay nuevos dispositivos de conexi&oacute;n. Accedemos a los contenidos no solamente desde un equipo de escritorio sino tambi&eacute;n desde tel&eacute;fonos, tablets u otros dispositivos.</p>
<p>Existe la necesidad de crear redes de aprendizaje: Reconociendo un mundo globalizado, el aprendizaje m&aacute;s significativo se va a dar por la experiencia de otras personas. De esta manera debemos contemplar la posibilidad de empezar a capitalizar la experiencia y conocimiento fuera de nuestros entornos primarios (empresa, colegio, universidad, etc.)</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FTin%20can%20API%20-%20IA%20de%20la%20formaci%C3%B3n%20y%20el%20desarrollo.ics?alt=media&token=00519041-0639-4bf5-bbc1-9dd0e7a17843', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Panel")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Panel")} flex`} style={{ position: "relative" }}>
                                <span>Panel</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded12} onClick={() => handleExpandClick(11)} aria-expanded={expanded12} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia">Panel El líder RIF</div>}
                        />
                        {!expanded12 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F20%20-%20Jose%20Raul%20Vargas%2FJose-raul-Vargas-85x85.png?alt=media&token=ac2dc197-10ff-45aa-beab-2173c8be9eb1" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                José Raúl Vargas
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente General - Sky Perú</div>}
                          />
                        )}
                        <Collapse in={expanded12} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F20%20-%20Jose%20Raul%20Vargas%2FJose-raul-Vargas-85x85.png?alt=media&token=ac2dc197-10ff-45aa-beab-2173c8be9eb1" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                José Raúl Vargas
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente General - Sky Perú</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F21%20-%20Lorena%20Dibos%2FLorena_Dibos_85x85.png?alt=media&token=20c8ca4c-a7d1-448c-8f40-d53be475705e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Lorena Dibos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente de Gestión Humana - Cencosud S.A.</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F22%20-%20Esteban%20Tellez%2FEsteban_Tellez_85x85.png?alt=media&token=c3a3fbfb-15d3-4adb-9b21-4755d46f4f2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Esteban Téllez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Chief Revenue Officer Latam - Siigo S.A</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Cu&aacute;les son las habilidades que deben tener los l&iacute;deres del ma&ntilde;ana, pensando en los aprendizajes y desaf&iacute;os que nos ha planteado la pandemia: gestionar equipos de forma h&iacute;brida, manejo de crisis, renuncias masivas, entre otros.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FEl%20l%C3%ADder%20RIF.ics?alt=media&token=80141b1d-113d-493c-b963-1ccb9abf8f35', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>}
              {isMobile && <Grid container direction="row" spacing={2}>
                {/* {day === 2 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  
                </Grid>} */}
                {day === 3 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Panel")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Panel")} flex`} style={{ position: "relative" }}>
                                <span>Panel</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded8} onClick={() => handleExpandClick(7)} aria-expanded={expanded8} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/8')}>Panel Tin can API - IA de la formación y el desarrollo</div>}
                        />
                        {!expanded8 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F15%20-%20Gustavo%20Escoqui%2FGustavo_Escoqui_85x85.png?alt=media&token=eff93046-ea4e-4572-b0b3-6e1062606a1d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Gustavo Escoqui
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Diretor de Tecnologia - 4U Edtech</div>}
                          />
                        )}
                        <Collapse in={expanded8} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F15%20-%20Gustavo%20Escoqui%2FGustavo_Escoqui_85x85.png?alt=media&token=eff93046-ea4e-4572-b0b3-6e1062606a1d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Gustavo Escoqui
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Diretor de Tecnologia - 4U Edtech</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F1%20-%20Camilo%20vergara%2FCamilo_Vergara_85x85.png?alt=media&token=ea7b8747-2ba5-48ce-9dd8-3064ff621bb6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Camilo Vergara
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">CEO - Spira </div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Tin Can API, Experience Api o X-Api es conocido generalmente como &ldquo;la nueva generaci&oacute;n del est&aacute;ndar SCORM&rdquo;; si bien a nivel t&eacute;cnico podr&iacute;a entenderse as&iacute;, TinCan Api es en verdad mucho m&aacute;s que eso. Es una herramienta que nos viene a ayudar para generar una nueva forma de experiencias de &ldquo;aprendizaje ubicuo&rdquo;.</p>
<p>Lo que queremos decir es que TinCan viene a facilitar cuatro deficiencias del Scorm: A pesar de todas sus ventajas, SCORM se ha quedado atr&aacute;s en los &uacute;ltimos a&ntilde;os por los cambios que han surgido en el contexto de la capacitaci&oacute;n online:</p>
<p>El aprendizaje debe ser m&oacute;vil. No solo se produce frente a un equipo y dentro del contexto de un LMS, sino tambi&eacute;n en un viaje mientras no exista conexi&oacute;n a Internet. Hay un potencial enorme en el aprendizaje informal. El aprendizaje no puede estar limitado a un pensum formal, tambi&eacute;n debe ocurrir en contextos informales como p&aacute;ginas web, canales de streaming, redes sociales o blogs.</p>
<p>Hay nuevos dispositivos de conexi&oacute;n. Accedemos a los contenidos no solamente desde un equipo de escritorio sino tambi&eacute;n desde tel&eacute;fonos, tablets u otros dispositivos.</p>
<p>Existe la necesidad de crear redes de aprendizaje: Reconociendo un mundo globalizado, el aprendizaje m&aacute;s significativo se va a dar por la experiencia de otras personas. De esta manera debemos contemplar la posibilidad de empezar a capitalizar la experiencia y conocimiento fuera de nuestros entornos primarios (empresa, colegio, universidad, etc.)</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FTin%20can%20API%20-%20IA%20de%20la%20formaci%C3%B3n%20y%20el%20desarrollo.ics?alt=media&token=00519041-0639-4bf5-bbc1-9dd0e7a17843', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
                {day === 4 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Panel")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Panel")} flex`} style={{ position: "relative" }}>
                                <span>Panel</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded12} onClick={() => handleExpandClick(11)} aria-expanded={expanded12} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia">Panel El líder RIF</div>}
                        />
                        {!expanded12 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F20%20-%20Jose%20Raul%20Vargas%2FJose-raul-Vargas-85x85.png?alt=media&token=ac2dc197-10ff-45aa-beab-2173c8be9eb1" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                José Raúl Vargas
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente General - Sky Perú</div>}
                          />
                        )}
                        <Collapse in={expanded12} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F20%20-%20Jose%20Raul%20Vargas%2FJose-raul-Vargas-85x85.png?alt=media&token=ac2dc197-10ff-45aa-beab-2173c8be9eb1" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                José Raúl Vargas
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente General - Sky Perú</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F21%20-%20Lorena%20Dibos%2FLorena_Dibos_85x85.png?alt=media&token=20c8ca4c-a7d1-448c-8f40-d53be475705e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Lorena Dibos
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Gerente de Gestión Humana - Cencosud S.A.</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F22%20-%20Esteban%20Tellez%2FEsteban_Tellez_85x85.png?alt=media&token=c3a3fbfb-15d3-4adb-9b21-4755d46f4f2e" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Esteban Téllez
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Chief Revenue Officer Latam - Siigo S.A</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F2%20-%20Nicola%CC%81s%20Solo%CC%81rzano%2FNicolas_Solorzano_85x85.png?alt=media&token=6270cf0c-3980-4a68-b808-5cab36ad2a5d" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Panel")}`} style={{ fontWeight: "bold" }}>
                                Nicolás Solórzano
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director Perú - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Cu&aacute;les son las habilidades que deben tener los l&iacute;deres del ma&ntilde;ana, pensando en los aprendizajes y desaf&iacute;os que nos ha planteado la pandemia: gestionar equipos de forma h&iacute;brida, manejo de crisis, renuncias masivas, entre otros.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FEl%20l%C3%ADder%20RIF.ics?alt=media&token=80141b1d-113d-493c-b963-1ccb9abf8f35', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
              </Grid>}
            </Grid>
          </Grid>
        </Container>

        {/* HORARIO DE LAS 16 PM */}
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2} className="padre">
              <div className="center hijos bold">16:00</div>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {!isMobile && <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded5} onClick={() => handleExpandClick(4)} aria-expanded={expanded5} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/4')}>Great resignation: El gran reto de fidelizar talentos</div>}
                        />
                        {!expanded5 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F10%20-%20Carolina%20Astaiza%2FCarolina_Ataiza_85x85.png?alt=media&token=8bc78652-4286-43dc-92ec-1f71daf7adf6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Carolina Astaiza
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Head - RappiPay</div>}
                          />
                        )}
                        <Collapse in={expanded5} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F10%20-%20Carolina%20Astaiza%2FCarolina_Ataiza_85x85.png?alt=media&token=8bc78652-4286-43dc-92ec-1f71daf7adf6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Carolina Astaiza
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Head - RappiPay</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>En el ultimo a&ntilde;o las cifras de renuncias masivas han incrementado de forma considerable, muchas organizaciones se han visto afectadas, pero &iquest;Qu&eacute; motiva este fen&oacute;meno? &iquest;C&oacute;mo afrontarlo? Son algunos de los cuestionamientos que conllevan un gran reto postpandemia &iquest;C&oacute;mo fidelizar los talentos de la compa&ntilde;&iacute;a?</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FGreat%20resignation%20El%20gran%20reto%20de%20fidelizar%20talentos.ics?alt=media&token=f3fbfb89-01b4-4a44-a278-656ec789004e', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Taller")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Taller")} flex`} style={{ position: "relative" }}>
                                <span>Taller</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded9} onClick={() => handleExpandClick(8)} aria-expanded={expanded9} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => window.open("https://us02web.zoom.us/j/86970984374?pwd=cHZkTm5FRkdDamFPNUZpUlpUU0hRZz09", "_blank")}>Taller: Neurociencia aplicada al desempeño de las presonas</div>}
                        />
                        {!expanded9 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F16%20-%20Tere%20Lucio%2FTere_Lucio_85x85.png?alt=media&token=e9cb6513-be14-4242-9f36-2ea2df28a4e3" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Tere Lucio
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora weCARE & Customer Care Associates - weCare</div>}
                          />
                        )}
                        <Collapse in={expanded9} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F16%20-%20Tere%20Lucio%2FTere_Lucio_85x85.png?alt=media&token=e9cb6513-be14-4242-9f36-2ea2df28a4e3" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Tere Lucio
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora weCARE & Customer Care Associates - weCares</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<div class=""ewa-rteLine"">Si la productividad es una &ldquo;decisi&oacute;n de retomar el poder de uno mismo para hacer un proyecto exitoso&rdquo;, esto se traduce en la posibilidad de que los momentos dif&iacute;ciles, se conviertan en el combustible para construir un mundo mejor y equipo empoderado.</div>
<div class=""ewa-rteLine"">&nbsp;</div>
<div class=""ewa-rteLine"">Durante este taller compartiremos c&oacute;mo transformar mi liderazgo para construir las nuevas capacidades y posibilidades que lleven a mi equipo a ser m&aacute;s exitoso desde la neurociencia&hellip; todo esto son las herramientas innovadoras para ser m&aacute;s PRODUCTIVO e ir m&aacute;s all&aacute; de lo posible.</div>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FTaller%20Neurociencia%20aplicada%20al%20desemp%C3%A9%C3%B1o%20de%20las%20presonas.ics?alt=media&token=8f120f95-5d5f-4a2c-8f6a-f00beac7c081', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Taller")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Taller")} flex`} style={{ position: "relative" }}>
                                <span>Taller</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded13} onClick={() => handleExpandClick(12)} aria-expanded={expanded13} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => window.open("https://us02web.zoom.us/j/88165321772?pwd=Yk1WQ3JRMVZPZTgvRmpCcnZqRkRNdz09", "_blank")}>Taller Spira</div>}
                        />
                        {!expanded13 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Paula Kley
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                          />
                        )}
                        <Collapse in={expanded13} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Paula Kley
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F23%20-%20Andre%CC%81s%20Lancheros%2FAndres_Lancheros_85x85.png?alt=media&token=2d6d4e9c-2e00-4a1b-b1ba-90783ad99cb5" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Andres Lancheros Avendaño
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director de Operaciones Perú - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F24%20-%20Johanna%20Sierra%2FJohanna_Sierra_85x85.png?alt=media&token=992454aa-b819-4186-a11f-5fa4d88ebcf8" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Johanna Sierra Ruiz
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultora de Entrenamiento y Desarrollo Spira México - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Identificar comportamientos y acciones clave que los equipos pueden integrar, para revolucionar, innovar y formarse de acuerdo al entorno, el talento y los indicadores del negocio.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FTaller%20Spira.ics?alt=media&token=5e8b481b-70be-4e93-9190-dd9f8338c464', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>
              </Grid>}
              {isMobile && <Grid container direction="row" spacing={2}>
                {day === 2 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Entrevista")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Entrevista")} flex`} style={{ position: "relative" }}>
                                <span>Entrevista</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded5} onClick={() => handleExpandClick(4)} aria-expanded={expanded5} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => navigate('/streaming/4')}>Great resignation: El gran reto de fidelizar talentos</div>}
                        />
                        {!expanded5 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F10%20-%20Carolina%20Astaiza%2FCarolina_Ataiza_85x85.png?alt=media&token=8bc78652-4286-43dc-92ec-1f71daf7adf6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Carolina Astaiza
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Head - RappiPay</div>}
                          />
                        )}
                        <Collapse in={expanded5} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F10%20-%20Carolina%20Astaiza%2FCarolina_Ataiza_85x85.png?alt=media&token=8bc78652-4286-43dc-92ec-1f71daf7adf6" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Carolina Astaiza
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">HR Head - RappiPay</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Entrevista")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>En el ultimo a&ntilde;o las cifras de renuncias masivas han incrementado de forma considerable, muchas organizaciones se han visto afectadas, pero &iquest;Qu&eacute; motiva este fen&oacute;meno? &iquest;C&oacute;mo afrontarlo? Son algunos de los cuestionamientos que conllevan un gran reto postpandemia &iquest;C&oacute;mo fidelizar los talentos de la compa&ntilde;&iacute;a?</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2FDia%201%2FGreat%20resignation%20El%20gran%20reto%20de%20fidelizar%20talentos.ics?alt=media&token=f3fbfb89-01b4-4a44-a278-656ec789004e', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
                {day === 3 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Taller")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Taller")} flex`} style={{ position: "relative" }}>
                                <span>Taller</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded9} onClick={() => handleExpandClick(8)} aria-expanded={expanded9} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => window.open("https://us02web.zoom.us/j/86970984374?pwd=cHZkTm5FRkdDamFPNUZpUlpUU0hRZz09", "_blank")}>Taller: Neurociencia aplicada al desempeño de las presonas</div>}
                        />
                        {!expanded9 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F16%20-%20Tere%20Lucio%2FTere_Lucio_85x85.png?alt=media&token=e9cb6513-be14-4242-9f36-2ea2df28a4e3" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Tere Lucio
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora weCARE & Customer Care Associates - weCare</div>}
                          />
                        )}
                        <Collapse in={expanded9} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F16%20-%20Tere%20Lucio%2FTere_Lucio_85x85.png?alt=media&token=e9cb6513-be14-4242-9f36-2ea2df28a4e3" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Tere Lucio
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Directora weCARE & Customer Care Associates - weCare</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F3%20-%20Andre%CC%81s%20Gonza%CC%81lez%2FAndres_Gonzalez_85x85.png?alt=media&token=c7743df0-3890-4509-bd5a-606d1186ad27" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Andrés González
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de estrategia Comercial - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<div class=""ewa-rteLine"">Si la productividad es una &ldquo;decisi&oacute;n de retomar el poder de uno mismo para hacer un proyecto exitoso&rdquo;, esto se traduce en la posibilidad de que los momentos dif&iacute;ciles, se conviertan en el combustible para construir un mundo mejor y equipo empoderado.</div>
<div class=""ewa-rteLine"">&nbsp;</div>
<div class=""ewa-rteLine"">Durante este taller compartiremos c&oacute;mo transformar mi liderazgo para construir las nuevas capacidades y posibilidades que lleven a mi equipo a ser m&aacute;s exitoso desde la neurociencia&hellip; todo esto son las herramientas innovadoras para ser m&aacute;s PRODUCTIVO e ir m&aacute;s all&aacute; de lo posible.</div>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%202%2FTaller%20Neurociencia%20aplicada%20al%20desemp%C3%A9%C3%B1o%20de%20las%20presonas.ics?alt=media&token=8f120f95-5d5f-4a2c-8f6a-f00beac7c081', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
                {day === 4 && <Grid item xs={12} sm={12} md={4} lg={4} className="padre">
                  <div className={`container-day`}>

                    <div className={`${cualCategoria("Taller")}`}>
                      <Card sx={{ maxWidth: '100%', minHeight: 200 }}>
                        <CardHeader
                          title={
                            <>
                              <div className={`categoria ${cualColor("Taller")} flex`} style={{ position: "relative" }}>
                                <span>Taller</span>
                                <span style={{ position: "absolute", right: '-17px', top: "-17px" }}>
                                  <ExpandMore expand={expanded13} onClick={() => handleExpandClick(12)} aria-expanded={expanded13} aria-label="show more">
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </span>
                              </div>
                            </>
                          }
                          subheader={<div className="nombre-confernecia cursor" onClick={() => window.open("https://us02web.zoom.us/j/88165321772?pwd=Yk1WQ3JRMVZPZTgvRmpCcnZqRkRNdz09", "_blank")}>Taller Spira</div>}
                        />
                        {!expanded13 && (
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Paula Kley
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                          />
                        )}
                        <Collapse in={expanded13} timeout="auto" unmountOnExit>
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F4%20-%20Paula%20Kley%2FPaula_Kley_85x85.png?alt=media&token=873a6ecf-a006-4c4b-a8a8-d610fa410f1b" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Paula Kley
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Líder de Consultoría - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F23%20-%20Andre%CC%81s%20Lancheros%2FAndres_Lancheros_85x85.png?alt=media&token=2d6d4e9c-2e00-4a1b-b1ba-90783ad99cb5" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Andres Lancheros Avendaño
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Director de Operaciones Perú - Spira</div>}
                          />
                          <CardHeader
                            avatar={
                              <img className="img-conferencista-circulo" src="https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Speakers%2F24%20-%20Johanna%20Sierra%2FJohanna_Sierra_85x85.png?alt=media&token=992454aa-b819-4186-a11f-5fa4d88ebcf8" alt="img" />
                            }
                            title={
                              <div className={`${cualColor("Taller")}`} style={{ fontWeight: "bold" }}>
                                Johanna Sierra Ruiz
                              </div>
                            }
                            subheader={<div className="puesto-conferencista-next">Consultora de Entrenamiento y Desarrollo Spira México - Spira</div>}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {parse(
                                `<p>Identificar comportamientos y acciones clave que los equipos pueden integrar, para revolucionar, innovar y formarse de acuerdo al entorno, el talento y los indicadores del negocio.</p>`
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Duración: 1 hrs.
                              <span onClick={() => downloadFile('https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/ics%2Fdia%203%2FTaller%20Spira.ics?alt=media&token=5e8b481b-70be-4e93-9190-dd9f8338c464', 'cita.ics')} className="cursor" style={{ float: 'right' }}><CalendarMonthIcon /></span>
                            </Typography>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                </Grid>}
              </Grid>}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
