export default function ValidateUser(props) {
  const { children } = props;
  const data = localStorage.getItem("3c393f98");
  let content = "";
  
  if (data) {
      content = <div>{children}</div>;
  } else {
    window.location.replace("/");
  }
  return content;
}
