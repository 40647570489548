import React, { useState, useEffect } from 'react';
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../../config/firebase";

import { useParams, useNavigate } from 'react-router-dom'
import { Container, Grid, TextField, Button, Paper, MenuItem, Select, Switch } from '@material-ui/core';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Swal from "sweetalert2";
import Header from '../../../../components/HeaderAdmin';
import InputSearch from "../../../../components/InputSearch";
import { updateData, searchDataByValue, getCollections } from '../../../../resources/js/functions'
import './styles.scss';

export default function ActivarConferencias() {
    const [data, setData] = useState([]);

    useEffect(() => {
        getConferences();
        const dbRef = ref(db, "conferencias/");
        onChildChanged(dbRef, (data) => {
            getConferences();
        });
    }, [])

    const getConferences = async () => {
        const conferencias = await getCollections("conferencias");
        setData(conferencias);
    };

    const activarConferencia = (id) => {
        const infoConferencia = data.filter((val) => val.id === id);
        const info = {
            activa: !infoConferencia[0].activa,
            chat: !infoConferencia[0].chat,
        };
        Swal.fire({
            title: `${infoConferencia[0].activa ? "¿Deseas desactivar la conferencia?" : "¿Deseas activar la conferencia?"}`,
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#f38f04",
            cancelButtonColor: "#e10613",
            denyButtonText: `Cancelar`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                updateData("conferencias", id, info).then(() => {
                    // window.location.reload()
                });
            }
        });
    }

    const encuesta = (id) => {
        const infoConferencia = data.filter((val) => val.id === id);
        const info = {
            encuesta: !infoConferencia[0].encuesta,
        };
        Swal.fire({
            title: `${infoConferencia[0].encuesta ? "¿Deseas desactivar la encuesta?" : "¿Deseas activar la encuesta?"}`,
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#f38f04",
            cancelButtonColor: "#e10613",
            denyButtonText: `Cancelar`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                updateData("conferencias", id, info);
            }
        });
    }

    const notificacion = (id) => {
        const infoConferencia = data.filter((val) => val.id === id);
        const info = {
            notificacion: !infoConferencia[0].notificacion,
        };
        Swal.fire({
            title: `${infoConferencia[0].activa ? "¿Deseas desactivar la notificación?" : "¿Deseas activar la notificación?"}`,
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#f38f04",
            cancelButtonColor: "#e10613",
            denyButtonText: `Cancelar`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                updateData("conferencias", id, info).then(() => {
                    // window.location.reload()
                });
            }
        });
    };


    let backgroundStyles = `#root::after{background: #e9e9e9};`;
    return <>
        <Header>
            <style>{backgroundStyles}</style>

            <div id='activarConferencias'>
                <Container maxWidth="lg">
                    <Paper elevation={3} style={{ padding: '15px' }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={11} lg={11}>
                                {Object.values(data).length > 0 ? (
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow className="abner">
                                                    <TableCell>
                                                        <div className="titulos titulo-encuesta">Conferencia</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="titulos titulo-encuesta">Activar</div>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <div className="titulos titulo-encuesta">Encuesta</div>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <div className="titulos titulo-encuesta">Notificación</div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.values(data).map((row) => {
                                                    return <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            <div className="nombre-conferencia">{row.nombreConferencia}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="full center">
                                                                <Switch checked={row.activa} onChange={() => activarConferencia(row.id)} color="primary" />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="full center">
                                                                <Switch checked={row.encuesta} onChange={() => encuesta(row.id)} color="primary" />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="full center">
                                                                <Switch checked={row.notificacion} onChange={() => notificacion(row.id)} color="primary" />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <div className="full center">
                                        <div className="titulos titulo-encuesta">Sin resultados</div>
                                    </div>
                                )}
                            </Grid>

                        </Grid>
                    </Paper>
                </Container>
            </div>
        </Header>
    </>
}