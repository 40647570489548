import React, { useState, useEffect } from 'react';
import { db } from "../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import ConferenciaActiva from './ConferenciaActiva';
import VideoIntroductorio from './VideoIntroductorio';
import { getCollections } from "../../../resources/js/functions";


export default function SeccionConferencia() {
  const [conferencia, setConferencia] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferences();
    });
    getConferences();
  }, []);

  const getConferences = async () => {
    const data = await getCollections("conferencias");
    const act = data.filter(val => val.activa === true);

    if (Object.values(act).length > 0) {
      setConferencia(true);
    } else {
      setConferencia(false);
    }
  };

  return <>
    {!conferencia && <VideoIntroductorio />}
    {conferencia && <ConferenciaActiva />}
  </>
}
