import React from 'react';
import AliceCarousel from "react-alice-carousel";
import { Grid, Container } from "@material-ui/core";
import info from './info.json';
import "react-alice-carousel/lib/alice-carousel.css";

export default function SeccionNuestrosProductos() {
    return <div id="seccion-nuestros-productos">
        <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <h1>Estos son nuestros productos:</h1>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <AliceCarousel
                        mouseTrackingEnabled
                        items={info}
                        autoPlayInterval="4000"
                        autoPlay={true}
                        infinite={true}
                        mouseTracking
                        startIndex={0}
                        slideToIndex={0}
                        disableButtonsControls
                        // disableDotsControls
                        responsive={{
                            425: {
                                items: 1,
                            },
                            640: {
                                items: 2,
                            },
                            1024: {
                                items: 2,
                            },
                        }}
                    >
                        {info.map((i, index) => {
                            return (
                                <>
                                    {/* <Grid item xs={12} sm={12} md={11} lg={11}> */}

                                    <div className="container-products" key={index}>
                                        <div className='id-product'>{i.titulo}</div>

                                        <iframe width="100%" title="streaming" src={i.video} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
                                    </div>
                                    {/* </Grid> */}
                                </>
                            );
                        })}
                    </AliceCarousel>
                </Grid>
            </Grid>
        </Container>


    </div>
}