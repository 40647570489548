import React from "react";
import { Grid, Container } from "@mui/material";

export default function SectionWelcome(props) {
  return (
    <div id="bienvenida" className="mb30">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <h1 className="titulos">¡Bienvenido!</h1>
            <p className="blanco">
              Sabemos que la transformación digital, innovación y nuevas tendencias hacen que los procesos se reinventen, transformen, formando nuevos conceptos y estrategias para lograr el éxito, por eso creamos este espacio para que tú que estas interesado en el capital humano, desarrollo organizacional, gestión del talento y recursos humanos puedas acompañarnos.
            </p>
            <p className="blanco">
              Contaremos con la participación de más de 20 speakers internacionales y con amplia experiencia en el mundo organizacional a través de entrevistas, ponencias, paneles y foros.
            </p>
            <p className="blanco">
              Recuerda que cada día, se habilitará el contenido correspondiente a la fecha de cada espacio.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
