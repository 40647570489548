import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { Grid, Container, Button } from "@mui/material";
// import parse from "html-react-parser";
import { getCollections, stringToArray } from "../../../../resources/js/functions";
import banner from "../../../../resources/images/home/banner.png";
import btnInfo from "../../../../resources/images/home/btn_info.svg";

export default function SectionConferenceActive(props) {
  const navigate = useNavigate();
  const [conferenciaActiva, setConferenciaActiva] = useState({});
  const [conferencia, setConferencia] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getConferences();
    });
    getConferences();
  }, []);

  const getConferences = async () => {
    const array = [];
    let json = {}
    const data = await getCollections("conferencias");
    const speakers = await getCollections("speakers");
    const act = data.filter(val => val.activa === true);
    const speakersId = stringToArray(act[0].speakers)
    Object.values(act).map(val => {
      Object.values(speakers).map(valID => {
        if (parseInt(val.speakers) === valID.id) {
          json = {
            ...val,
            infoSpeaker: valID
          }
          array.push(json)
        }
      })
    })
    console.log(array);

    if (Object.values(act).length > 0) {
      setConferenciaActiva(array[0]);
      setConferencia(true);
    } else {
      setConferencia(false);
    }
  }

  if (Object.keys(conferenciaActiva).length === 0) {
    return null;
  }

  return (
    <div id='conferencia-activa'>
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={1}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={conferenciaActiva.urlVideo} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="target-conference">
                  <div className="name-conference bold">{conferenciaActiva.nombreConferencia}</div>
                  <div className="full padre">
                    <img className="hijos" style={{ borderRadius: '50%', width: '70px', height: '70px', margin: '10px 0' }} src={conferenciaActiva.infoSpeaker.urlFotoCuadrada} alt="img" />
                    <div className="hijos conferencista">
                      {conferenciaActiva.infoSpeaker.nombre}
                      <span className="hijos">{conferenciaActiva.infoSpeaker.puesto}</span>
                    </div>
                  </div>
                  <div className="full">
                    <div className="name-conferencista full padre">
                      <span className="instrucciones bold hijos" style={{ display: "flex" }}>
                        <span>
                          Ingresa a la sala de este espacio, participa en el chat y conoce más información
                          <br />
                          <br />
                          <span className="job-conferencista">
                            Haz clic en{" "}
                            <span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "15px", color: '#1426ed' }} onClick={() => navigate(`/streaming/${conferenciaActiva.id}`)}>
                              Entrar
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
