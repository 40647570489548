import React from 'react';
import { Grid } from "@material-ui/core";

const videos = [
    'https://player.vimeo.com/video/703887765?h=cd38ce6ef6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'https://player.vimeo.com/video/704172237?h=09dea6d108&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'https://player.vimeo.com/video/705482048?h=6f181a421a',
    'https://player.vimeo.com/video/706115176?h=ef13418317',
    'https://player.vimeo.com/video/698754402?h=b37e1dcbc7',
    'https://player.vimeo.com/video/698745504?h=fae3b7567c',
    'https://player.vimeo.com/video/698902639?h=97330dd6cb',
]

const descargables = [
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%201%2FPORTADA-Situacio%CC%81n%20actual%20del%20Mundo%20Hi%CC%81brido.png?alt=media&token=3546b683-eb30-47ca-8382-1e9a59809572',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%201%2FSituaci%C3%B3n%20actual%20del%20Mundo%20H%C3%ADbrido.pdf?alt=media&token=fee023e0-8827-48ac-acf3-d3ecc67a7f29'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%201%2FPortada%20-%208%20tacticas%20para%20ejercer%20influencia%20y%20evolucionar.png?alt=media&token=015a7c01-6127-44bb-91dc-3a918db0b689',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%201%2F8%20tacticas%20para%20ejercer%20influencia%20y%20evolucionar.pdf?alt=media&token=60d46772-de3b-440c-9668-c876df837d43'
    }
]

export default function DiaUno() {
    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                {Object.values(videos).map(val => (
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <iframe width="100%" title="streaming" src={val} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" />
                    </Grid>
                ))}
            </Grid>
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                        {Object.values(descargables).map((val, index) => {
                            return (
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <div className="bg-toolkit padre">
                                        <img className='full' src={val.portada} alt="as" />
                                    </div>
                                    <div className="full center hijos">
                                        <div className="btn-azul cursor" style={{ padding: '10px', margin: 'auto' }} onClick={() => window.open(val.url, "_blank")}>Descargar</div>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}