import React, { useState, useEffect } from 'react';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getCollections } from "../../../resources/js/functions";
import Linkedin from "../../../resources/images/footer/linkedin.png";


const diaUno = [5, 7, 9, 10, 25, 26, 31, 1, 2, 3];
const diaDos = [11, 12, 13, 14, 15, 16, 27, 28, 1, 2, 3];
const diaTres = [17, 18, 19, 20, 21, 22, 29, 32, 1, 2, 3, 4]; //falta el id 20

export default function SpeakersToday() {
    const [speakersDiaUno, setSetSpeakersDiaUno] = useState([]);

    useEffect(() => {
        getSpeakers();
    }, []);

    const getSpeakers = async () => {
        const data = await getCollections('speakers');
        const array = [];
        const d = new Date();
        const idDay = d.getDay();

        switch (idDay) {
            case 2:
                Object.values(diaUno).map(id => {
                    Object.values(data).map(valData => {
                        if (id === valData.id) {
                            array.push(valData);
                        }
                    });
                })
                break;
            case 3:
                Object.values(diaDos).map(id => {
                    Object.values(data).map(valData => {
                        if (id === valData.id) {
                            array.push(valData);
                        }
                    });
                })
                break;
            case 4:
                Object.values(diaTres).map(id => {
                    Object.values(data).map(valData => {
                        if (id === valData.id) {
                            array.push(valData);
                        }
                    });
                })
                break;

            default:
                Object.values(diaUno).map(id => {
                    Object.values(data).map(valData => {
                        if (id === valData.id) {
                            array.push(valData);
                        }
                    });
                })
                break;
        }

        // console.log(array);
        setSetSpeakersDiaUno(array);
    }

    return <div id="speakers-today">
        <AliceCarousel
            mouseTrackingEnabled
            items={speakersDiaUno}
            autoPlayInterval="4000"
            autoPlay={true}
            infinite={true}
            mouseTracking
            startIndex={0}
            slideToIndex={0}
            disableButtonsControls
            // disableDotsControls
            responsive={{
                425: {
                    items: 2,
                },
                640: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },
            }}
        >
            {speakersDiaUno.map((i, index) => {
                return (
                    <div className="container-speaker">
                        <div style={{ backgroundImage: `url(${i.urlFotoCuadrada})` }} className="img-conferencista" />
                        {i.urlEmpresaLogo !== ' ' ? <div className="container-img-logo"><img src={i.urlEmpresaLogo} alt="logo" className="logo" /></div> : ''}
                        <div className="name">{i.nombre}</div>
                        <div className="job">{`${i.puesto} - ${i.empresa}`}</div>
                        <div className="social-media">
                            <img src={Linkedin} alt="social.img" onClick={() => window.open(i.nombre === "Blanya Correal y Paula Franco" ? "https://www.linkedin.com/in/blanya-cristina-correal-sarmiento-460254b/" : `${i.linkedin}`, "_blank")} />
                            {i.nombre === "Blanya Correal y Paula Franco" && <img src={Linkedin} alt="social.img" style={{ float: 'right' }} onClick={() => window.open("https://www.linkedin.com/in/pafranco/", "_blank")} />}
                        </div>
                    </div>
                );
            })}
        </AliceCarousel>
    </div>
}
