import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getCollections, top } from "../../resources/js/functions";
import Linkedin from "../../resources/images/footer/linkedin.png";
import "./styles.scss";

const diaUno = [5, 7, 9, 10, 25, 26, 31, 1, 2, 3];
const diaDos = [11, 12, 13, 14, 15, 16, 27, 28, 1, 2, 3];
const diaTres = [17, 18, 19, 20, 21, 22, 29, 32, 1, 2, 3, 4, 23, 24]; //falta el id 20

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Speakers() {
  const [value, setValue] = useState(0);
  const [speakersDiaUno, setSetSpeakersDiaUno] = useState([]);
  const [speakersDiaDos, setSetSpeakersDiaDos] = useState([]);
  const [speakersDiaTres, setSetSpeakersDiaTres] = useState([]);
  const d = new Date();

  useEffect(() => {
    getSpeakers();
    top();
    if (d.getDay() === 2 || d.getDay() === 3 || d.getDay() === 4) {
      setValue(d.getDay() - 2)
    }
  }, [])

  const getSpeakers = async () => {
    const arrayUno = [];
    const arrayDos = [];
    const arrayTres = [];
    const data = await getCollections('speakers');

    Object.values(diaUno).map(id => {
      Object.values(data).map(valData => {
        if (id === valData.id) {
          arrayUno.push(valData);
        }
      });
    })
    Object.values(diaDos).map(id => {
      Object.values(data).map(valData => {
        if (id === valData.id) {
          arrayDos.push(valData);
        }
      });
    })
    Object.values(diaTres).map(id => {
      Object.values(data).map(valData => {
        if (id === valData.id) {
          arrayTres.push(valData);
        }
      });
    })
    setSetSpeakersDiaUno(arrayUno);
    setSetSpeakersDiaDos(arrayDos);
    setSetSpeakersDiaTres(arrayTres);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ValidateUser>
      <Header />
      <div id="speakers">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <h1>¡Descrubre nuestros speakers!</h1>
              <p>
                Conoce aquí los speakers de la 2da Cumbre Spira: Revolución – Innovación – Formación. Líderes de opinión, expertos y gestores de cambios organizacionales que nos acompañarán en estos tres días y compartirán su conocimiento, experiencias, visiones y mejores prácticas para aumentar el desempeño y revolucionar de forma innovadora en los procesos formativos.
              </p>
            </Grid>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <div className="full center">
                <Tabs centered value={value} onChange={handleChange} variant="fullWidth" scrollButtons="auto" aria-label="scrollable auto tabs example">
                  <Tab label="Martes 3 de Mayo" wrapped />
                  <Tab label="Miercoles 4 de Mayo" wrapped />
                  <Tab label="Jueves 5 de Mayo" wrapped />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid container direction="row" spacing={3}>
                    {Object.values(speakersDiaUno).map((val, index) => (
                      <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                        <div className="container-speaker">
                          <div className="img-conferencista">
                            <img src={val.urlFotoCuadrada} alt="img" className="full" />
                          </div>
                          {val.urlEmpresaLogo !== ' ' && <div className="container-img-logo"><img src={val.urlEmpresaLogo} alt="logo" className="logo" /></div>}
                          <div className="name">{val.nombre}</div>
                          <div className="job">{val.empresa !== ' ' ? `${val.puesto} - ${val.empresa}` : `${val.puesto}`}</div>
                          <div className="social-media">
                            <img src={Linkedin} alt="social.img" onClick={() => window.open(val.nombre === "Blanya Correal y Paula Franco" ? "https://www.linkedin.com/in/blanya-cristina-correal-sarmiento-460254b/" : `${val.linkedin}`, "_blank")} />
                            {val.nombre === "Blanya Correal y Paula Franco" && <img src={Linkedin} alt="social.img" style={{ float: 'right' }} onClick={() => window.open("https://www.linkedin.com/in/pafranco/", "_blank")} />}
                            {/* https://www.linkedin.com/in/blanya-cristina-correal-sarmiento-460254b/ */}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container direction="row" spacing={3}>
                    {Object.values(speakersDiaDos).map((val, index) => (
                      <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                        <div className="container-speaker">
                          <div className="img-conferencista">
                            <img src={val.urlFotoCuadrada} alt="img" className="full" />
                          </div>
                          {val.urlEmpresaLogo !== ' ' && <div className="container-img-logo"><img src={val.urlEmpresaLogo} alt="logo" className="logo" /></div>}
                          <div className="name">{val.nombre}</div>
                          <div className="job">{val.empresa !== ' ' ? `${val.puesto} - ${val.empresa}` : `${val.puesto}`}</div>
                          <div className="social-media">
                            <img src={Linkedin} alt="social.img" onClick={() => window.open(`${val.linkedin}`, "_blank")} />
                            {/* <img src={Facebook} alt="social.img" onClick={() => window.open("https://es-la.facebook.com", "_blank")} /> */}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid container direction="row" spacing={3}>
                    {Object.values(speakersDiaTres).map((val, index) => (
                      <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                        <div className="container-speaker">
                          <div className="img-conferencista">
                            <img src={val.urlFotoCuadrada} alt="img" className="full" />
                          </div>
                          {val.urlEmpresaLogo !== ' ' && <div className="container-img-logo"><img src={val.urlEmpresaLogo} alt="logo" className="logo" /></div>}
                          <div className="name">{val.nombre}</div>
                          <div className="job">{val.empresa !== ' ' ? `${val.puesto} - ${val.empresa}` : `${val.puesto}`}</div>
                          <div className="social-media">
                            <img src={Linkedin} alt="social.img" onClick={() => window.open(val.nombre === "Paula Ruiz y Gustavo González" ? "https://www.linkedin.com/in/paula-ruiz-figueroa-59257a124/" : `${val.linkedin}`, "_blank")} />
                            {val.nombre === "Paula Ruiz y Gustavo González" && <img src={Linkedin} alt="social.img" style={{ float: 'right' }} onClick={() => window.open("https://www.linkedin.com/in/graficologo/", "_blank")} />}
                            {/* <img src={Facebook} alt="social.img" onClick={() => window.open("https://es-la.facebook.com", "_blank")} /> */}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </ValidateUser>
  );
}
