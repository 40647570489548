import React, { useState } from "react";
import SectionCertificate from "./SectionCertificate";
import SectionQuestions from "./SectionQuestions";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ValidateUser from "../../components/ValidateUser";

export default function Certificate() {
  const [vista, setVista] = useState(0);

  const activeView = (id) => {
    setVista(id);
  };

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="certificate">
          {vista === 0 && <SectionQuestions activeView={activeView} />}
          {vista === 1 && <SectionCertificate activeView={activeView} />}
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
