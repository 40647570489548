import React from "react";
import { Grid, Container } from "@material-ui/core";
import CarouselSocios from "./CarouselSocios";

export default function SectionSocios() {
  return (
    <div className="section-socios">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <div className="full center">
              <h1>Nuestros aliados</h1>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <CarouselSocios />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
