import React from 'react';
import { Container, Grid } from '@mui/material';

export default function VideoIntroductorio() {
    return (
        <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <div className="contenedor-streaming">
                        <iframe title="streaming" src="https://player.vimeo.com/video/704173047?h=b457b07238&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
