import React, { useState, useEffect } from "react";
// import firebase from "firebase";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import parse from "html-react-parser";
import uniqid from "uniqid";
import ReactStars from "react-rating-stars-component";
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";
import Encuesta from "./Encuesta";
import SpeakersConferencias from "./SpeakersConferencias";
import { searchData, stringToArray, getCollections, setData, getDate, updateData, searchDataByValue } from "../../resources/js/functions";
import EstrellaAmarilla from "../../resources/images/streamingOnLine/estrella-amarilla.svg";
import EstrellaVerde from "../../resources/images/streamingOnLine/estrella-verde.svg";
import EstrellaMedia from "../../resources/images/streamingOnLine/estrella-media.svg";
import "./styles.scss";

export default function StreamingOnLine() {
  const params = useParams();
  const idConferencia = params.id;

  const [likes, setLikes] = useState([false, false])
  const [dataInfo, setDataInfo] = useState([]);
  const [speakersActive, setSpeakersActive] = useState([]);

  const [modal, setModal] = useState(false);
  // ranking
  const [paso, setPaso] = useState(0);
  const [preguntas, setPreguntas] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getVideo();
    getConferences();
    getlikes();
    const dbRef = ref(db, "conferencias/");
    onChildChanged(dbRef, (data) => {
      getVideo();
      getConferences();
    });
  }, []);

  const getConferences = async () => {
    const result = await searchData("conferencias", idConferencia);
    if (result.activa && result.encuesta) {
      // console.log("activa");
      setModal(result.encuesta);
    } else {
      // console.log("no activa");
    }
  };

  const getVideo = async () => {
    let arraySpeakers = [];
    let arraySpeakersInfo = [];
    const result = await searchData(`conferencias/`, params.id);
    setDataInfo(result);
    const idSpeakers = stringToArray(result.speakers);
    const speakersResults = await getCollections("speakers");
    Object.values(idSpeakers).map((id) => {
      Object.values(speakersResults).map((idSpeaker) => {
        if (parseInt(id) === idSpeaker.id) {
          arraySpeakersInfo.push(idSpeaker);
        }
      });
    });

    // // const arrayId = stringToArray(window.location.pathname, "/");
    // const resultActiva = result.filter((val) => val.activa === true);
    // arraySpeakers = stringToArray(resultActiva[0].speakers);

    //   // const speaker = speakersResults[id];
    //   arraySpeakersInfo.push(info[0]);
    setSpeakersActive(arraySpeakersInfo);
  };

  const Raking = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    value: 0,
    a11y: true,
    isHalf: true,
    emptyIcon: <img src={EstrellaVerde} alt="estrella" />,
    halfIcon: <img src={EstrellaMedia} alt="estrella" />,
    filledIcon: <img src={EstrellaAmarilla} alt="estrella" />,
    onChange: (value) => {
      if (paso === 1) {
        setPreguntas({ ...preguntas, preguntaDos: value });
      }
      if (paso === 2) {
        setPreguntas({ ...preguntas, preguntaTres: value });
      }

      if (paso < 4) {
        setPaso(paso + 1);
      }
    },
  };

  const respuestaUno = (valor) => {
    setPreguntas({ ...preguntas, preguntaUno: valor });
    setPaso(paso + 1);
    // setPregunta3(valor);
    // setPaso(paso + 1);
  };

  const respuestaCuatro = (valor) => {
    setPreguntas({ ...preguntas, preguntaCuatro: valor });
    // setPregunta3(valor);
    // setPaso(paso + 1);
  };

  const cerrar = () => {
    // const db = firebase.database();
    // const ref = await b.ref(`encuestas/${dataInfo.id}/${uuidv4()}`);
    const identificador = uniqid();
    let json = {};
    json[identificador] = {
      ...preguntas,
      id: identificador,
      idUser: localStorage.getItem('3c393f98'),
      fechaRegistro: getDate(),
    };
    // ref.set(respuestas);

    updateData("encuestas", idConferencia, json).then(() => {
      setModal(false);
      setPaso(0);
    });
  };

  const getlikes = async () => {
    const result = await searchData(`likes/`, params.id);
    if (Object.values(result).length > 0) {
      const values = Object.values(result);
      const likeUser = values.filter(val => val.idUser === localStorage.getItem('3c393f98'));
      if (likeUser.length > 0) {
        if (likeUser[0].like) {
          setLikes([true,false]);
        } else {
          setLikes([false,true]);
        }
      }
    }
  } 

  const likesSet = async (idSelect) => {
    const id = uniqid();
    let json = {};
    let like = "";
    let array = [false, false];
    switch (idSelect) {
      case 0:
        like = true;
        array[0] = true;
        break;
      case 1:
        like = false;
        array[1] = true;
        break;
    
      default:
        break;
    }
    
    setLikes(array);
    const result = await searchData(`likes/`, params.id);
    if (Object.values(result).length > 0) {
      const values = Object.values(result);
      const likeUser = values.filter(val => val.idUser === localStorage.getItem('3c393f98'));
      if (likeUser.length > 0){
        json[likeUser[0].id] = {
          like: !likeUser[0].like,
          idUser: localStorage.getItem('3c393f98'),
          id: likeUser[0].id,
          fechaRegistro: getDate()
        }
      } else {
        json[id] = {
          like,
          idUser: localStorage.getItem('3c393f98'),
          id,
          fechaRegistro: getDate()
        }
      }
      updateData(`likes`, idConferencia, json)
        .then(() => {
        }).catch(
          e => console.log(e)
        );
    } else {
      json[id] = {
        like,
        idUser: localStorage.getItem('3c393f98'),
        id,
        fechaRegistro: getDate()
      }
      setData('likes', idConferencia, json)
      .then(() => { 
      }).catch(
        e => console.log(e)
        );
    }
  }

  if (dataInfo.length === 0) {
    return null;
  }

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="streamingOnLine">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="go-back cursor" onClick={() => navigate("/home")}>
                  {"< Regresar"}
                </div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{dataInfo.nombreConferencia}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {parse(`${dataInfo.descripcion !== undefined && dataInfo.descripcion}`)}
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={dataInfo.urlVideo} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>

              </Grid>
              {dataInfo.chat && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={dataInfo.urlChat} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
              <Grid item lg={dataInfo.chat ? 12 : 8} md={12} xs={12}>
                <div>
                  <span className={`likes-btn ${likes[0] && 'btn-likes-selected'}`} onClick={() => likesSet(0)}>
                    <ThumbUpIcon />
                  </span>
                  <span className={`likes-btn ${likes[1] && 'btn-likes-selected'}`} onClick={() => likesSet(1)}>
                    <ThumbDownAltIcon />
                  </span>
                </div>
              </Grid>
              <SpeakersConferencias speakersActive={speakersActive} />
            </Grid>
          </Container>
          <Modal open={modal}>
            <Container maxWidth="lg">
              <div className="contenedor-encuesta">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item lg={12} md={12} xs={12}>
                    {/* <div className="full">
                      <div className="cursor" style={{ textAlign: 'right', color: '#ccc' }} onClick={() => setModal(false)}><CloseIcon /></div>
                    </div> */}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <div className="full titulo-encuesta">2da Cumbre Spira: Revolución – Innovación – Formación</div>
                  </Grid>
                  {/* <div className="full titulo-encuesta">{Encuesta[paso].titulo}</div> */}
                  <Grid item lg={12} md={12} xs={12}>
                    <div className="full pregunta-encuesta">{Encuesta[paso].pregunta}</div>
                  </Grid>
                  <div className="full ranking">
                    {paso === 0 && (
                      <>
                        <FormControl>
                          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                              <Grid item lg={6} md={6} xs={12}>
                                <FormControlLabel onChange={() => respuestaUno("Totalmente relevante")} value="Totalmente relevante" control={<Radio />} label="Totalmente relevante" />

                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <FormControlLabel onChange={() => respuestaUno("Relevante")} value="Relevante" control={<Radio />} label="Relevante" />
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>

                                <FormControlLabel onChange={() => respuestaUno("Poco relevante")} value="Poco relevante" control={<Radio />} label="Poco relevante" />
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>

                                <FormControlLabel onChange={() => respuestaUno("No tiene relevancia")} value="No tiene relevancia" control={<Radio />} label="No tiene relevancia" />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                    {paso === 1 && (
                      <Grid item lg={12} md={12} xs={12}>
                        <ReactStars classNames="div-estrellas" {...Raking} />
                      </Grid>
                    )}
                    {paso === 2 && (
                      <Grid item lg={12} md={12} xs={12}>
                        <ReactStars classNames="div-estrellas" {...Raking} />
                      </Grid>
                    )}
                    {paso === 3 && (
                      <>
                        <FormControl>
                          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                              <Grid item lg={12} md={12} xs={12}>
                                <FormControlLabel onChange={() => respuestaCuatro("Si")} value="Si" control={<Radio />} label="Si" />

                              </Grid>
                              <Grid item lg={12} md={12} xs={12}>
                                <FormControlLabel onChange={() => respuestaCuatro("No")} value="No" control={<Radio />} label="No" />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                    {paso === 3 && (
                      <>
                        <Grid item lg={12} md={12} xs={12}>
                          <div className="full div-botones">
                            <div className="btn-naranja cursor" style={{ padding: "10px" }} onClick={cerrar}>
                              Guardar
                            </div>
                          </div>
                        </Grid>
                      </>
                    )}
                  </div>
                  <Grid item lg={12} md={12} xs={12}>
                    <div className="full center div-paso"> {`${paso + 1}/4`}</div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </Modal>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
