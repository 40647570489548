import React from 'react';
import { Grid } from "@material-ui/core";

const videos = [
    'https://player.vimeo.com/video/704186949?h=3982b711b2',
    'https://player.vimeo.com/video/703889547?h=ef6525a126',
    'https://player.vimeo.com/video/704172871?h=99d5443910',
    'https://player.vimeo.com/video/704172602?h=620a34acd3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'https://player.vimeo.com/video/706638973?h=80c3d47b06',
    'https://player.vimeo.com/video/706692269?h=5a7266eb1b',
    'https://player.vimeo.com/video/706739308?h=2961698ffa',
]

const descargables = [
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FPORTADA%20Escenarios%20digitales.png?alt=media&token=309cced6-db71-4aea-ab65-2b33de4acbd7',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FInfograf%C3%ADa_RIF_-_AVAS_OVAS.pdf?alt=media&token=dc837fe0-1ee1-43bc-a7f5-2c5c5569a126'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FPORTADA%20Habit%20Tracker.png?alt=media&token=d05e9f0d-86e5-4eaf-af97-481c35877092',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FPlaneador.pdf?alt=media&token=a6c32b9f-7195-43fe-ba05-71ed6a328014'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FPortada-%20Influence%20Model.png?alt=media&token=185e809e-24fd-4169-ba19-28b75adf448e',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FInfluence%20Model.pdf?alt=media&token=2d0834af-86ff-4c91-8c46-f813656b9733'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FPortada%20taller%20cierre%20spira.png?alt=media&token=4664889d-3f5d-4872-b782-bb5f42195f57',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%203%2FTaller_cierre_spira.pdf?alt=media&token=1748c3e9-dbd0-4790-be72-6df16780a02e'
    }
]

export default function DiaTres() {
    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                {Object.values(videos).map(val => (
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <iframe width="100%" title="streaming" src={val} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" />
                    </Grid>
                ))}
            </Grid>
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                        {Object.values(descargables).map((val, index) => {
                            return (
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <div className="bg-toolkit padre">
                                        <img className='full' src={val.portada} alt="as" />
                                    </div>
                                    <div className="full center hijos">
                                        <div className="btn-azul cursor" style={{ padding: '10px', margin: 'auto' }} onClick={() => window.open(val.url, "_blank")}>Descargar</div>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}