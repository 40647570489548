import mexico from "../../../resources/images/networking/mexico.png";
import colombia from "../../../resources/images/networking/colombia.png";
import ecuador from "../../../resources/images/networking/ecuador.png";
import peru from "../../../resources/images/networking/peru.png";
import guatemala from "../../../resources/images/networking/guatemala.png";
import argentina from "../../../resources/images/networking/argentina.png";

const info = [
	{
		pais: 'Colombia',
		img: colombia,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
	{
		pais: 'México',
		img: mexico,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
	{
		pais: 'Guatemala',
		img: guatemala,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
	{
		pais: 'Perú',
		img: peru,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
	{
		pais: 'Argentina',
		img: argentina,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
	{
		pais: 'Ecuador',
		img: ecuador,
		urlLinkedin: '',
		urlWhatsApp: '',
		citaCalendly: 'https://calendly.com/mercadeospira/logremos-grandes-cosas-juntos-agendemos-30-minutos-cumbrerif?month=2022-04',
	},
];

export default info;
