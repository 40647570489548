export const imagenes = [
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-EVALUAR.png?alt=media&token=68c33cfe-5094-4e77-90b4-2be355e88830',
		efecto: true,
		url: 'https://www.youtube.com/watch?v=aWDtXTi1RoI',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-HUMAN-Blanco.png?alt=media&token=15d3d919-861d-43da-bd0d-5e3caf647104',
		efecto: true,
		url: 'http://www.grupohuman.ec/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-MINDFUL-CARE.png?alt=media&token=d9122604-34f5-4ba2-8e9c-9ac8e24250a4',
		efecto: true,
		url: 'https://mindful.care/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-OPINAT.png?alt=media&token=2035771f-673f-4a25-a18c-01770945fd61',
		efecto: false,
		url: 'https://www.opinat.com/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-PDA.png?alt=media&token=57615079-1cf6-46ce-bffb-f78dc900d345',
		efecto: true,
		url: 'https://pdainternational.net/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-TAMIM.png?alt=media&token=7c13708b-bfdd-4d06-902e-def48b61138f',
		efecto: true,
		url: 'https://www.linkedin.com/company/tamim-hr-consulting/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-UBITS.png?alt=media&token=d795b663-9339-4a4a-8a43-cd413b2ac067',
		efecto: true,
		url: 'https://www.ubits.com/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-VIEWY.png?alt=media&token=01ca23fb-58d2-48c9-999b-26d163e6cbdd',
		efecto: false,
		url: 'https://viewy.com.co/',
	},
	{
		img: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Aliados%2FLogo-238X140-WEWOW.png?alt=media&token=71555c92-1ca3-486c-bf9e-58146e59fcbb',
		efecto: false,
		url: 'https://wewow.com.mx/',
	},
];
