import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import parse from "html-react-parser";
import { Grid, Container } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getCollections, searchData, stringToArray } from "../../../resources/js/functions";

export default function SectionDescription() {
  const [expanded, setExpanded] = useState(false);
  const [nextConference, setnextConference] = useState({});
  const [nextConferenceSpeak, setnextConferenceSpeak] = useState({});

  useEffect(() => {
    const dbRef = ref(db, "configuracion/e6hejpg3");
    onChildChanged(dbRef, (data) => {
      get();
    });
    get();
  }, []);

  const get = async () => {
    const data = await getCollections("configuracion/e6hejpg3");
    const getConference = await searchData("conferencias", data[0]);
    setnextConference(getConference);
    let arraySpeakersInfo = [];
    const speakersResults = await getCollections("speakers");
    const idSpeakers = stringToArray(getConference.speakers);
    Object.values(idSpeakers).map((id, index) => {
      Object.values(speakersResults).map((idSpeaker) => {
        if (index === 0) {
          if (parseInt(id) === idSpeaker.id) {
            arraySpeakersInfo.push(idSpeaker);
          }
        }
      });
    });
    setnextConferenceSpeak(arraySpeakersInfo);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const cualCategoria = (cat) => {
    let color = "";
    switch (cat) {
      case "Foro":
        color = "categoria-uno";
        break;
      case "Entrevista":
        color = "categoria-dos";
        break;
      case "Pnencia":
        color = "categoria-tres";
        break;
      case "Taller":
        color = "categoria-cuatro";
        break;

      default:
        break;
    }
    return color;
  };

  if (Object.values(nextConferenceSpeak).length === 0) {
    return null;
  }

  return <Container maxWidth="lg" style={{ marginBottom: '60px' }}>
    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <h1>¡Descubre lo que pasa todos los días!</h1>
        <p>
          A continuación encontrarás la agenda de cada día, al dar clic en la flecha de cada tema podrás desplegar la información del live y los speakers, también podrás acceder a los que ya se realizaron dando click sobre el título de cada evento.
        </p>
        <div className="divider" />
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={6} sm={2} md={2} lg={2}>
            <div className="contenedor-categoria padre">
              <div className="padres">
                <div className="color-categoria bg-categoria-uno hijos" style={{ border: '1px solid #fff' }} />
                &nbsp;
                <div className="nombre-categoria hijos">Foro</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={2} md={2} lg={2}>
            <div className="contenedor-categoria padre">
              <div className="padres">
                <div className="color-categoria bg-categoria-dos hijos" style={{ border: '1px solid #fff' }} />
                &nbsp;
                <div className="nombre-categoria hijos">Entrevista</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={2} md={2} lg={2}>
            <div className="contenedor-categoria padre">
              <div className="padres">
                <div className="color-categoria bg-categoria-tres hijos" style={{ border: '1px solid #fff' }} />
                &nbsp;
                <div className="nombre-categoria hijos">Ponencia</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={2} md={2} lg={2}>
            <div className="contenedor-categoria padre">
              <div className="padres">
                <div className="color-categoria bg-categoria-cuatro hijos" style={{ border: '1px solid #fff' }} />
                &nbsp;
                <div className="nombre-categoria hijos">Taller</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <div className="contenedor-categoria padre">
              <div className="padres">
                <div className="color-categoria bg-categoria-cinco hijos" style={{ border: '1px solid #fff' }} />
                &nbsp;
                <div className="nombre-categoria hijos">Panel</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} id="next-conferencia">
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <div className="next-event">Proximo evento: {nextConference.hora}</div>
            <div className={`categoria ${cualCategoria(nextConference.categoria)}`}>{nextConference.categoria}</div>
            <div className="nombre-confernecia">{nextConference.nombreConferencia}</div>
            <div className="contenedor-speaker padre">
              <img src={nextConferenceSpeak[0].urlFoto} alt="img" className="img-conferencista-next hijos" />
              <div className="hijos">
                <div className="nombre-conferencista-next">{nextConferenceSpeak[0].nombre}</div>
                <div className="puesto-conferencista-next">{`${nextConferenceSpeak[0].empresa ? `${nextConferenceSpeak[0].puesto} - ${nextConferenceSpeak[0].empresa}` : nextConferenceSpeak[0].puesto}`}</div>
              </div>
            </div>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography color="text.secondary" className="descripcion-confernecista-next">
                {parse(`${nextConference.descripcion}`)}
              </Typography>
              <div className="duracion">{`Duración: ${nextConference.duracion} hrs.`}</div>
            </CardContent>
          </Collapse>
          <CardActions disableSpacing>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  </Container>
}