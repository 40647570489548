import React, { useState } from "react";
import { Grid, Container, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { setData, getDate } from "../../../resources/js/functions";

export default function SectionQuestions(props) {
  const { activeView } = props;
  const [importante, setImportante] = useState([]);
  const [preguntas, setPreguntas] = useState({});

  const registrar = async () => {
    if (Object.values(preguntas).length < 9) {
      Swal.fire({
        title: `¡Error!`,
        text: `Existen preguntas sin responder`,
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#f38f04",
        cancelButtonColor: "#e10613",
        icon: "error",
      });
    } else {
      let id = uniqid();
      const json = {
        ...preguntas,
        id,
        preguntaCinco: importante.toString(),
        fechaDeRegistro: getDate(),

      };
      setData("encuestaCertificado", id, json).then(() => {
        activeView(1);
      });
    }
  };

  return (
    <div id="section-questions">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="titulos">2da Cumbre Spira: Revolución – Innovación – Formación   </div>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="">
              Gracias por haber sido parte de la segunda Cumbre Spira llevada a cabo del 3 al 5 de mayo de 2022. ¡Fue un gusto contar con tu participación! Esperamos que todo el contenido compartido haya sido de gran valor para ti y tu
              organización, logrando generar hábitos productivos que te lleven a alcanzar tus objetivos.
            </div>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Cuál es tu correo?</div>
            <TextField type="email" label="" autoComplete="true" size="small" fullWidth variant="standard" onChange={(e) => setPreguntas({ ...preguntas, correo: e.target.value })} />
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Cómo fue tu experiencia en la 2da cumbre Spira: RIF? </div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: "Excelente" })} value="Excelente" control={<Radio />} label="Excelente" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: "Buena" })} value="Buena" control={<Radio />} label="Buena" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: "Regular" })} value="Regular" control={<Radio />} label="Regular" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: "Deficiente" })} value="Deficiente" control={<Radio />} label="Deficiente" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Cómo calificarías la calidad del evento? </div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: "Excelente" })} value="Excelente" control={<Radio />} label="Excelente" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: "Buena" })} value="Buena" control={<Radio />} label="Buena" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: "Regular" })} value="Regular" control={<Radio />} label="Regular" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: "Deficiente" })} value="Deficiente" control={<Radio />} label="Deficiente" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Llenaron tus expectativas los ponentes invitados?</div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaCuatro: "Si" })} value="Si" control={<Radio />} label="Si" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaCuatro: "No" })} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Cuál o cuáles fueron los días más interesante para ti? (Selecciona uno o varios) </div>
            <FormControl>
              {/* <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group"> */}
                {/* <FormControlLabel onChange={() => setDiaInteresante({ ...preguntas, preguntaCinco: "Revolución" })} value="Revolución" control={<Radio />} label="Revolución" /> */}
              <FormControlLabel onChange={() => setImportante([...importante, "Revolución"])} value="Revolución" control={<Radio />} label="Revolución" />
              <FormControlLabel onChange={() => setImportante([...importante, "Innovación"])} value="Innovación" control={<Radio />} label="Innovación" />
              <FormControlLabel onChange={() => setImportante([...importante, "Formación"])} value="Formación" control={<Radio />} label="Formación" />
              {/* </RadioGroup> */}
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Sientes que fue útil la información que recibiste? </div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSeis: "Si" })} value="Si" control={<Radio />} label="Si" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSeis: "No" })} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Cómo te enteraste del evento? </div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSiete: "Email de SPIRA " })} value="Email de SPIRA " control={<Radio />} label="Email de SPIRA " />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSiete: "Un speaker/ invitado " })} value="Un speaker/ invitado " control={<Radio />} label="Un speaker/ invitado " />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSiete: "Publicidad" })} value="Publicidad" control={<Radio />} label="Publicidad" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaSiete: "Redes sociales" })} value="Redes sociales" control={<Radio />} label="Redes sociales" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Conocías Spira?</div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaOcho: "Si" })} value="Si" control={<Radio />} label="Si" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaOcho: "No" })} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Te gustaría participar en un próximo evento? </div>
            <FormControl>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaNueve: "Si" })} value="Si" control={<Radio />} label="Si" />
                <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaNueve: "No" })} value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <div className="pregunta">¿Tienes algún comentario, felicitación, recomendación u oportunidad de mejora para nosotros? Compártela aquí </div>
            {/* <TextField id="outlined-multiline-flexible" label="" fullWidth multiline maxRows={9} onChange={(e) => setPreguntas({ ...preguntas, preguntaDiez: e.target.value })} /> */}
            <TextField type="text" label="" autoComplete="true" size="small" fullWidth variant="standard" onChange={(e) => setPreguntas({ ...preguntas, preguntaDiez: e.target.value })} />
            {/* <TextField id="standard-multiline-static" label="" fullWidth multiline rows={4} variant="standard" onChange={(e) => setPreguntas({ ...preguntas, preguntaDiez: e.target.value })} /> */}
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Button className="btn-azul blanco" onClick={registrar}>
              ¡Listo!
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
