const array = [
	{
		texto: 'Home',
		link: '/home',
	},
	{
		texto: 'Agenda',
		link: '/agenda',
	},
	{
		texto: 'Espacios',
		link: '/espacios',
	},
	{
		texto: 'Speakers',
		link: '/speakers',
	},
	{
		texto: 'Agenda una cita',
		link: '/agenda-una-cita',
	},
];

export default array;
