// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAr-D_5BjyO6bfEw3N8fIRzA5jc5-zSZSQ",
  authDomain: "spira-cumbre-2022-aed45.firebaseapp.com",
  projectId: "spira-cumbre-2022-aed45",
  storageBucket: "spira-cumbre-2022-aed45.appspot.com",
  messagingSenderId: "696886869643",
  appId: "1:696886869643:web:73f19c07d2999999231e8e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
