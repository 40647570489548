import React, { useState, useEffect } from 'react';
import { Grid, Container } from "@material-ui/core";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import ValidateUser from "../../components/ValidateUser";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DiaUno from '../Home/SeccionQueVeremosHoy/DiaUno';
import DiaDos from '../Home/SeccionQueVeremosHoy/DiaDos';
import DiaTres from '../Home/SeccionQueVeremosHoy/DiaTres';
import SpeakersDelDia from './SpeakersDelDia';
import './styles.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Espacios() {
    const [day, setDay] = useState(0);

    useEffect(() => {
        const d = new Date();
        if (d.getDay() === 2) {
            setDay(0);
        }
        if (d.getDay() === 3) {
            setDay(1);
        }
        if (d.getDay() === 4) {
            setDay(2);
        }
    }, [])

    const handleChange = (event, newValue) => {
        setDay(newValue);
    };

    return (
        <ValidateUser>
            <Header />
            <div id="espacios">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <h1>¡Bienvenido a espacios!</h1>
                            <p>
                                Esta será una experiencia única, en donde conocerás las herramientas, tendencias y nuevas miradas del entorno formativo en un contexto que exige modelos híbridos, agilidad y efectividad.
                            </p>
                            <p>
                                Durante estos 3 días descubrirás cómo revolucionar de forma innovadora los procesos formativos en las organizaciones.
                            </p>
                            <p>
                                Recuerda que cada día, se habilitará el contenido correspondiente a la fecha entrevistas, cápsulas, foros, ponencias y/o talleres.
                            </p>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Tabs centered value={day} onChange={handleChange} variant="fullWidth" scrollButtons="auto" aria-label="scrollable auto tabs example">
                                <Tab label="Martes 3 de Mayo" wrapped />
                                <Tab label="Miércoles 4 de Mayo" wrapped />
                                <Tab label="Jueves 5 de Mayo" wrapped />
                            </Tabs>

                            <TabPanel value={day} index={0}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h3>REVOLUCIÓN </h3>
                                        <p>
                                            Bienvenido a nuestro primer día de la 2da Cumbre Spira: Revolución – Innovación – Formación
                                        </p>
                                        <p>
                                            Hoy hablaremos de Revolución como volver a evolucionar, teniendo en cuenta los cambios generados a partir de la transformación digital y las nuevas tendencias.
                                        </p>
                                    </Grid>
                                    <DiaUno />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={day} index={1}>
                                <>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <h3>INNOVACIÓN</h3>
                                            <p>
                                                Bienvenido a nuestro segundo día de la 2da Cumbre Spira: 2da Cumbre Spira: Revolución – Innovación – Formación
                                            </p>
                                            <p>
                                                Hoy vamos a explorar algunas inquietudes en torno a Innovación y como integrarte a las nuevas tendencias en el mundo de los recursos humanos y mejores estrategias para la atracción, formación y fidelización del talento.
                                            </p>
                                        </Grid>
                                        <DiaDos />
                                    </Grid>
                                </>
                            </TabPanel>
                            <TabPanel value={day} index={2}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h3>FORMACIÓN </h3>
                                        <p>
                                            Hoy hablaremos de la Formación en torno a las nuevas tendencias tanto presenciales como virtuales, conocimientos clave para el desempeño de los colaboradores y logros en resultados.
                                        </p>
                                    </Grid>
                                   <DiaTres />
                                </Grid>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <h2>Los speakers de hoy:</h2>
                        </Grid>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <SpeakersDelDia />
                        </Grid>
                    </Grid>
                </Container>
            </div >
            <Footer />
        </ValidateUser >
    )
}