import React, { useEffect } from "react";
import { top } from '../../resources/js/functions';
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SeccionBienvenida from "./SeccionBienvenida";
import SeccionNuestrosProductos from "./SeccionNuestrosProductos";
import SectionCountries from "./SectionCountries";
import "./styles.scss";

export default function AgendaUnaCita() {
  useEffect(() => {
    top();
  }, [])
  
  return (
    <>
      <ValidateUser>
        <Header />
        <div id="agenda-una-cita">
          <SeccionBienvenida />
          <SeccionNuestrosProductos />
          <SectionCountries />
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
