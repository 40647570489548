import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { PropTypes } from "prop-types";
import { Toolbar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Snackbar } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Menu from "../Menu";
import IconAlert from "../../resources/images/header/icon-alert.png";
import LogoNegro from "../../resources/images/header/logo-negritas.png";
import LogoBlanco from "../../resources/images/header/logo-blanco.svg";
import "./styles.scss";

const styles = () => ({
  grow: {
    flexGrow: 1,
  },
});

const Header = (props) => {
  const { children, bg } = props;
  const [alerta, setAlerta] = useState({ nombreConferencia: "" });
  const [seActiva, setSeActiva] = useState(false);
  const [alertaShow, setAlertaShow] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });
  const navigate = useNavigate();
  const dbRef = ref(db, "conferencias/");
  const { vertical, horizontal, open } = alertaShow;

  useEffect(() => {
    nuevaConferencia();
    onChildChanged(dbRef, (data) => {
      //   console.log('====================================');
      //   console.log(data.val());
      //   console.log('====================================');
      //  if(!data.val().activa){

      // setAlertaShow({
      //   open: true,
      //   vertical: "bottom",
      //   horizontal: "left",
      // });

      //  }
      nuevaConferencia();
    });
    const header = document.getElementById("myHeader");
    // const navega = document.getElementById("menuHeader");
    const imgBanner = document.getElementById('imgBanner');
    // const sticky = header.offsetTop;

    window.addEventListener("scroll", (event) => {
      let scroll = window.scrollY;
      if (scroll > 0) {
        header.classList.add("bg-azul");
        // navega.classList.add("text-header-sticky");
        imgBanner.classList.add('transform-img');
      } else {
        if (!bg) {
          header.classList.remove("bg-azul");
          // navega.classList.remove("text-header-sticky");
        }
        imgBanner.classList.remove('transform-img');
      }
    });
    // const scrollCallBack = window.addEventListener('scroll', () => {
    // });
    // return () => {
    // 	window.removeEventListener('scroll', scrollCallBack);
    // };
  }, []);

  const nuevaConferencia = () => {
    onChildChanged(dbRef, (data) => {
      const nuevaConferencia = data.val();
      setAlerta(nuevaConferencia);
      setAlertaShow({
        open: nuevaConferencia.notificacion ? true : false,
        vertical: "bottom",
        horizontal: "left",
      })
    });
  };

  const handleClose = (event, reason) => {
    setAlertaShow({ ...alertaShow, open: false });
  };

  const action = (
    <Button color="secondary" size="small">
      <AccessAlarmIcon />
    </Button>
  );

  return (
    <>
      <Toolbar id="myHeader" className={`header mb60 ${bg ? "bg-azul" : ""}`}>
        <Menu bg={bg} />

        <div className={styles.grow} />
      </Toolbar>

      <Stack spacing={2}>
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose} action={action} onClick={() => navigate(`/streaming/${alerta.id}`)}>
          <div className="center alert padre">
            <img src={IconAlert} alt="icon" className="hijos" />
            <span className="hijos" style={{ textAlign: "initial" }}>
              <span className="title">
                Transmitiendo en este momento <br />
              </span>
              <span className="title-conference">{`${alerta.nombreConferencia.substring(0, 50)}...`}</span>
            </span>
          </div>
        </Snackbar>
      </Stack>
    </>
  );
};
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bg: PropTypes.bool,
};
export default Header;
