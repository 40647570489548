import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import DiaUno from "./DiaUno";
import DiaDos from "./DiaDos";
import DiaTres from "./DiaTres";
import "./styles.scss";

export default function SectionWhatSeeToday(props) {
  const [day, setDay] = useState(2)
  const dia = new Date();

  useEffect(() => {
    get();
  }, []);

  const get = () => {
    if (dia.getDay() === 2 || dia.getDay() === 3 || dia.getDay() === 4) {
      setDay(dia.getDay());
    } else {
      setDay(2);
    }
  };

  return (
    <>
      <div id="what-see-today">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>¿Qué más veremos hoy?</h1>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <div className={`dia-espacio cursor ${day === 2 && 'btn-azul blanco'}`} onClick={() => setDay(2)}>Martes 3 <br />de mayo</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <div className={`dia-espacio cursor ${day === 3 && 'btn-azul blanco'}`} onClick={() => setDay(3)}>Miércoles 4 <br />de mayo</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <div className={`dia-espacio cursor ${day === 4 && 'btn-azul blanco'}`} onClick={() => setDay(4)}>Jueves 5 <br />de mayo</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              {day === 2 && <DiaUno />}
              {day === 3 && <DiaDos />}
              {day === 4 && <DiaTres />}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
