import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputSearch from '../../../components/InputSearch';
import Header from '../../../components/HeaderAdmin';
import ValidateAdmin from '../../../components/ValidateAdmin';
import { getCollections, deleteData } from '../../../resources/js/functions';
import './styles.scss';

const columns = [
    { id: 'id', label: 'Número de empleado', minWidth: 170 },
    { id: 'nombre', label: 'Nombre', minWidth: 170 },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

export default function Conferencias() {

    let backgroundStyles = `#root::after{background: #fff};`;

    return <>
        <ValidateAdmin>
            <Header>
                <style>{backgroundStyles}</style>

                <div id="conferencias">
                    asass
                </div>
            </Header >
        </ValidateAdmin>
    </>
}