import React, { useEffect } from "react";
import { top } from '../../resources/js/functions';
import SectionDescription from './SectionDescription';
import SectionSchedule from './SectionSchedule';
import ValidateUser from "../../components/ValidateUser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./styles.scss";

export default function Schedule() {
  useEffect(() => {
    top();
  }, [])

  return (
    <ValidateUser>
      <Header />
      <div id="schedule">
        <SectionDescription />
        <SectionSchedule />
      </div>
      <Footer />
    </ValidateUser>
  );
}
