export default [
	{
		titulo: 'EVALUACIÓN DEL CONTENIDO',
		pregunta: '¿El tema del live es relevante para ti o tu organización?',
	},
	{
		titulo: ' * ',
		pregunta: `En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías al speaker?`,
	},
	{
		titulo: 'RECOMENDACIONES',
		pregunta: 'En una escala del 1 al 5, siendo el 5 el más alto, ¿Cómo calificarías el live? ',
	},
	{
		titulo: 'RECOMENDACIONES',
		pregunta: '¿Te gustaría que este evento se lo mostremos a tu equipo de trabajo como una demo? ',
	},
];
