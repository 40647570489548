import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { Grid, Container, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import Connect from "../../resources/images/footer/spira-connect.svg";
import linkedin from "../../resources/images/footer/linkedin.svg";
import facebook from "../../resources/images/footer/facebook.svg";
import instagram from "../../resources/images/footer/instagram.svg";
import "./styles.scss";

const date = new Date().getFullYear();

export default function Footer() {
  const navigate = useNavigate();

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log("error: " + error);
        // An error happened.
      });
  };

  return (
    <>
      <div id="footer">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} sm={4} md={6} lg={3}>
              <div className="logo-connect">
                <img src={Connect} alt="img" className="img-connect" />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={6} className="center">
              <div className="conatiner-options">
                <div className="titulo-menu bold">Spira Connect</div>
                <div className="option" onClick={() => navigate("/home")}>Home</div>
                <div className="option" onClick={() => navigate("/agenda")}>Agenda</div>
                <div className="option" onClick={() => navigate("/espacios")}>Espacios</div>
              </div>
              <div className="conatiner-options">
                <div className="option" onClick={() => navigate("/agenda-una-cita")}>Agenda una cita</div>
                <div className="option" onClick={() => navigate("/speakers")}>Speaker</div>
              </div>
              {/* <Grid container direction="row" alignItems="center" justifyContent="center"> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="as">
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="">
                  </div>
                </Grid> */}
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="out-sesion" >
                <Button type="submit" className="btn-azul" onClick={logout}>
                  Cerrar sesión
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="divider" />
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={8} md={9} lg={9}>
              <div className="all-rights-reserved">&copy; {`Derechos reservados, Spira ${date}`}</div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="social-media" >
                <div className="follow-us padres" style={{ display: "inline-block" }}>
                  <span className="hijoss">Siguenos en</span>
                  <img src={linkedin} alt="img" className="hijos cursor" onClick={() => window.open("https://www.linkedin.com/company/spirahabitosproductivos/mycompany/", "_blank")} />
                  <img src={facebook} alt="img" className="hijos cursor" onClick={() => window.open("https://www.facebook.com/SpiraConsultoria", "_blank")} />
                  <img src={instagram} alt="img" className="hijos cursor" onClick={() => window.open("https://www.instagram.com/somos_spira/?hl=es-la", "_blank")} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
