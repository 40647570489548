import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Grid, TextField, Button } from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Paises from "./pasies";
import { setData, getDate, searchDataByValue } from "../../../resources/js/functions";
import '../styles.scss';

export default function FormReset(props) {
  const [campos, setCampos] = useState({});
  const { activeView } = props;

  const registrar = async (e) => {
    e.preventDefault();

    const existCorreo = await searchDataByValue("users", "correo", campos.correo);
    if (Object.keys(existCorreo).length > 0) {
      Swal.fire({
        title: "¡Error!",
        text: "Este correo electrónico ya se encuentra registrado",
        icon: "warning",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#f38f04",
      });
    } else {
      if (!campos.politicas) {
        Swal.fire({
          title: "¡Error!",
          text: "Por favor acepta los términos y condiciones",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#f38f04",
        });
      } else {
        const uid = uniqid();
        let json = {
          fechaRegistro: getDate(),
          ultimaConexion: "-",
          ...campos,
          numTelefono: `+${campos.numTelefono}`,
          id: uid,
        };

        await setData("users", uid, json).then(() => {
          Swal.fire({
            title: "¡Bien hecho!",
            text: "Registro exitoso",
            icon: "success",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#f38f04",
          }).then((result) => {
            if (result.isConfirmed) {
              activeView(1);
            }
          });
        })
      }
    }
  };

  const setDualCode = (paisSelected) => {
    const paisFilter = Paises.filter((val) => val.name_es === paisSelected);
    setCampos({ ...campos, pais: paisSelected, numTelefono: paisFilter[0].dial_code })
  }

  return (
    <Grid item xs={12} sm={6} md={7} lg={7}>
      <div className="form-registro">
        <div className="contenedor-form-login">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="text-form">Regístrate</div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Nombre" variant="filled" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Apellido" variant="filled" onChange={(e) => setCampos({ ...campos, apellido: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Correo" type="email" variant="filled" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                options={Paises}
                autoHighlight
                onChange={(e) => setDualCode(e.target.innerText)}
                getOptionLabel={(option) => option.name_en}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt="" />
                    {option.name_es}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="País"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <InputMask mask="999 999 99 99 99" value={campos.numTelefono} disabled={false} maskChar=" " onChange={(e) => setCampos({ ...campos, numTelefono: e.target.value })}>
                {() => <TextField fullWidth size="small" required label="Número de teléfono celular" variant="filled" InputLabelProps={{ shrink: campos.numTelefono ? true : false }} />}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField fullWidth size="small" required label="Empresa" variant="filled" onChange={(e) => setCampos({ ...campos, empresa: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField fullWidth size="small" required label="Cargo" variant="filled" onChange={(e) => setCampos({ ...campos, cargo: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox value={campos.politicas} onChange={() => setCampos({ ...campos, politicas: !campos.politicas })} />} label="*Acepto términos y condiciones conforme a la política de tratamiento de datos universal." />
                <a className="hijos" style={{ color: "#fff", textDecoration: "underline", fontSize: '12px' }} href="https://spira.co/politica-de-proteccion-de-datos/" rel="noreferrer" target="_blank">
                  Ver más
                </a>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="full space-footer-form">
                <Button className="btn" onClick={registrar}>
                  Registrarse
                </Button>
                <span className="register">
                  <span className="register-text" onClick={() => activeView(1)}>
                    Regresar
                  </span>
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
}
