import React from "react";
import { Grid, Container } from "@material-ui/core";
import info from "./infoNetworking";
import linkedin from "../../../resources/images/networking/linkedin.png";
import whatsapp from "../../../resources/images/networking/whatsapp.png";

export default function SectionCountries() {
  return (
    <div className="bg-blue">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <div className="full center titulo-paises-section">¡Agenda tu cita por país!</div>
              </Grid>
              {Object.values(info).map((val, index) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                  <div className="bg" style={{ backgroundImage: `url(${val.img})` }}></div>
                  <div className="info-redes center full">
                    <span className="btn-naranja calendly" onClick={() => window.open(val.citaCalendly, "_blank")}>
                      Cita Calendly
                    </span>
                    {/* <span>
                      <img src={whatsapp} alt="as" onClick={() => window.open(val.urlWhatsApp, "_blank")} />
                      <img src={linkedin} alt="as" onClick={() => window.open(val.urlLinkedin, "_blank")} />
                    </span> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
