import { BrowserRouter, Route, Routes } from "react-router-dom";
// Vistas
import Login from "../views/Login";
import Home from "../views/Home";
import StreamingOnLine from "../views/StreamingOnLine";
import Schedule from "../views/Schedule";
import Speakers from "../views/Speakers";
import Espacios from "../views/Espacios";
import AgendaUnaCita from "../views/AgendaUnaCita";
import Certificate from "../views/Certificate";
import LoginAdministrador from "../views/Adminstracion/Login";
import HomeAdministrador from "../views/Adminstracion/Home";
import Conferencias from '../views/Adminstracion/Conferencias';
import Activar from '../views/Adminstracion/Conferencias/Activar';
import Likes from '../views/Adminstracion/Conferencias/Likes';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/agenda-una-cita" element={<AgendaUnaCita />} />
        <Route exact path="/streaming/:id" element={<StreamingOnLine />} />
        <Route exact path="/speakers" element={<Speakers />} />
        <Route exact path="/agenda" element={<Schedule />} />
        <Route exact path="/espacios" element={<Espacios />} />
        <Route exact path="/certificado" element={<Certificate />} />

        {/* 
        <Route exact path="/libro-de-visitas" element={<Guestbook />} />
        <Route exact path="/comentarios" element={<Comments />} />
        <Route exact path="/reportes-connect" element={<Reporte />} />
       */}
        <Route exact path="/admin/login" element={<LoginAdministrador />} />
        <Route exact path="/admin/dashboard" element={<HomeAdministrador />} />
        <Route exact path="/admin/conferencias" element={<Conferencias />} />
        <Route exact path="/admin/conferencias/activar" element={<Activar />} />
        <Route exact path="/admin/conferencias/reporte-de-likes" element={<Likes />} />

        {/* <Route exact path="/panel-admin-connect" element={<Adminstracion />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
