import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Button, Hidden } from "@material-ui/core";
import Pdf from "react-to-pdf";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { searchDataByValue, top } from "../../../resources/js/functions";
import Certificado from "../../../resources/images/certificate/certificado.jpg";
import "../styles.scss";

export default function Certificate() {
  const [user, setUser] = useState({});
  const [left, setLeft] = useState(null);
  const ref = React.createRef();
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    top();
  }, []);

  const getUser = async () => {
    const usuario = await searchDataByValue("users", "id", localStorage.getItem("3c393f98"));
    console.log(usuario.nombre.length);
    if (usuario.nombre.length > 30){
      setLeft(23);
    }
    if (usuario.nombre.length > 10 && usuario.nombre.length < 18){
      setLeft(34);
    }
    // console.log(usuario);
    setUser(usuario);
  };

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 1],
  };

  const printDocument = () => {
    var w = document.getElementById("imgCertificado").offsetWidth;
    var h = document.getElementById("imgCertificado").offsetHeight;
    // html2canvas(document.getElementById("imgCertificado") {
    //   dpi: 300, // Set to 300 DPI
    //   scale: 3, // Adjusts your resolution
    //   onrendered: function(canvas) {
    //     var img = canvas.toDataURL("image/jpeg", 1);
    //     var doc = new jsPDF('L', 'px', [w, h]);
    //     doc.addImage(img, 'JPEG', 0, 0, w, h);
    //     doc.save('sample-file.pdf');
    //   }
    // });
    html2canvas(ref.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("L", "px", [w, h]);
      // const imgProps = pdf.getImageProperties(imgData);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "png", 0, 0, w, h);
      pdf.save("certificado.pdf");
    });
  };

  return (
    <div id="section-certificate">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <div className="titulos">¡Lo lograste!</div>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <div className="subtitulos">Gracias por tu participación en nuestra 2da Cumbre Spira: Revolución – Innovación – Formación. Ha sido un gusto contar contigo en los diferentes espacios.  </div>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <p className="text">
              Esperamos que todo el contenido haya sido de gran valor para ti y tu organización. Para descargar tu certificado por favor haz clic en el botón "Descargar", confirma tus datos y ¡compártelo en tus redes sociales! #2daCumbreSpiraRIF
            </p>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12} style={{ background: "#F8F7F7" }} ref={ref}>
            <div className="center full">
              <div id="imgCertificado" style={{ backgroundImage: `url(${Certificado})` }} className="certificate">
                <div className="name" style={{left: `${left}%`}}>{`${user.nombre}`}</div>
                {/* <div className="name-two">{`${user.nombre} ${user.apellido}`}</div>
                <div className="job">{user.cargo}</div> */}
              </div>
            </div>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
              <Grid item lg={10} md={6} sm={12} xs={12}>
                <div className="titulos">¡Felicidades!</div>
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <Pdf targetRef={ref} options={options} x={0.5} y={0.2} scale={0.8} filename="certificado.pdf">
                  {({ toPdf }) => (
                    <Button className="btn-azul blanco" onClick={printDocument}>
                      Descargar PDF
                    </Button>
                  )}
                </Pdf>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <p className="text">Puedes descargar tu certificado PDF.</p>
            <br />
          </Grid>
        </Grid>
      </Container>
      <section className="contact-us">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <div className="center full title-thank-message">¡Nos encantó colaborar contigo!</div>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <div className="center full text-message">
                Si tienes dudas o te interesa recibir más información de nuestros productos o servicios
                <br />
                <span>¡Contáctanos!</span>
              </div>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <div className="full center">
                <Button className="btn-azul blanco" onClick={() => navigate('/agenda-una-cita')}>¡Contáctanos!</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
}
