import React from "react";
import { Grid, Container } from "@material-ui/core";
import Carousel from "./Carousel";
import './styles.scss';

export default function ConferenciasDelDia() {
  return (
    <div id="conferencias-del-dia">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <h1>Nuestras conferencias de hoy</h1>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Carousel />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
