import React from 'react';
import { Grid } from "@material-ui/core";

const videos = [
    'https://player.vimeo.com/video/703888974?h=e61742bbe3',
    'https://player.vimeo.com/video/703888371?h=1e07f46a88&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'https://player.vimeo.com/video/703889056?h=fc90f658c1',
    'https://player.vimeo.com/video/706192231?h=c86fb1af8e',
    'https://player.vimeo.com/video/706235027?h=8e3b2db92f',
    'https://player.vimeo.com/video/706323935?h=cbea0d6908',
    'https://player.vimeo.com/video/706519384?h=9f98667bdb',
]

const descargables = [
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FPORTADA%20La%20nueva%20generacio%CC%81n%20del%20esta%CC%81ndar%20SCORM.png?alt=media&token=6a7390c3-554a-45be-888d-2e4c7fbdb9c6',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FLa%20nueva%20generaci%C3%B3n%20del%20est%C3%A1ndar%20SCORM.pdf?alt=media&token=f6b550d0-427e-41c8-a97e-7eaf2aa03ea4'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FPortada%20-%203%20preguntas%20para%20generar%20innovacio%CC%81n.png?alt=media&token=15571654-d0f4-470f-b214-0120a4bedd8f',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2F3%20preguntas%20para%20generar%20innovaci%C3%B3n.pdf?alt=media&token=7e87f4b9-58bc-49dc-b01a-d315a9cbd39c'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FPortada%20-%20El%20enfoque%20para%20identificar%20que%20quieres%20innovar.png?alt=media&token=019b532e-6868-4452-ac94-688ab6687b8c',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FEl%20enfoque%20para%20identificar%20que%20quieres%20innovar.pdf?alt=media&token=ad29144a-84bc-4927-a4b1-4773774ab9cb'
    },
    {
        portada: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FPortada%20-%20Oportunidades%20de%20mejora%20para%20innovar.png?alt=media&token=48cd52ae-53fc-40d6-bfd1-e7d261d697ed',
        url: 'https://firebasestorage.googleapis.com/v0/b/spira-cumbre-2022-aed45.appspot.com/o/Descargables%2FDia%202%2FOportunidades%20de%20mejora%20para%20innovar.pdf?alt=media&token=8441a4cc-71ad-4fa3-b88a-7e749037d304'
    }
]

export default function DiaDos() {
    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                {Object.values(videos).map(val => (
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <iframe width="100%" title="streaming" src={val} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" />
                    </Grid>
                ))}
            </Grid>
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                        {Object.values(descargables).map((val, index) => {
                            return (
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <div className="bg-toolkit padre">
                                        <img className='full' src={val.portada} alt="as" />
                                    </div>
                                    <div className="full center hijos">
                                        <div className="btn-azul cursor" style={{ padding: '10px', margin: 'auto' }} onClick={() => window.open(val.url, "_blank")}>Descargar</div>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}